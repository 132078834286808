export class AccessDTO {
    user: string | undefined;
    data: {
        ip: string | undefined;
        count: number | undefined;
        agent: string | undefined;
        viewport: string | undefined;
        query_string: string | undefined;
        timestamp: number | undefined;
    } | undefined;
}