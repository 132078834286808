import reportWebVitals from './reportWebVitals';
import ReactDOM from 'react-dom';
import '../src/styles/main.scss';
import Routes from './routes';
import * as Sentry from "@sentry/react";
Sentry.init({
  dsn: "https://0b5f7df904a9453ea42dc1785a09e911@o4505213032464384.ingest.sentry.io/4505229537902592",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", "https://adquira.cartaodetodos.com.br", "https://adquira.dev.cartaodetodos.com.br", "http://checkout.cartaodetodos.com.br.s3-website-sa-east-1.amazonaws.com"],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


declare global {
  interface Window {
    adyen: { encrypt: { createEncryption: any, createEncryptedForm: any } },
    dataLayer: any,
  }
}

ReactDOM.render(
  <Routes />
  , document.getElementById('root'));

reportWebVitals();