import { Modal, Row, Col } from "react-bootstrap";
import { dateComparePeriod } from "../utils/utils";

export const ModalRefiliacaoComponente = (props: any) => {
    return <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
        className="modal-refiliacao"
    >
        <Modal.Header className="justify-content-end">
            {/* <div
                className="d-flex align-items-center pointer"
                onClick={props.onHide}
            >
                <strong className="text-white">Fechar (X)</strong>
            </div> */}
        </Modal.Header>

        {dateComparePeriod(`${process.env.REACT_APP_ENVIRONMENT === "dev" ? '11/01/2023' : '11/24/2023'}`, "11/27/2023").insidePeriod ?
            <Modal.Body className="blackfriday">
                <Row className="area-refiliacao">
                    <Col className="col-auto d-xl-none">
                        <img src="/todos/refiliacao/blackfriday-mobile.png" alt="refilacao" />
                    </Col>
                    <Col>
                        <h1 className="title-refiliacao">
                            Consultamos aqui e <br className="d-none d-xl-block" />
                            você <br className="d-xl-none" /> já foi nosso cliente
                        </h1>
                        <p className="text-refiliacao mb-4">
                            <strong>Ótimo ter você de volta!</strong> Em alguns minutos{" "}
                            <br className="d-none d-xl-block" />
                            um de nossos atendentes <br className="d-xl-none" /> <strong> entrará em{" "}
                                <br className="d-none d-xl-block" /> contato via telefone </strong> com mais detalhes.
                        </p>

                    </Col>
                    <Col className="col-auto d-none d-xl-block">
                        <img src="/todos/refiliacao/blackfriday.png" alt="refilacao" />
                    </Col>
                </Row>
            </Modal.Body>

            :
            <Modal.Body>
                <Modal.Body className="default">
                    <Row className="align-items-center">
                        <Col className="col-auto d-xl-none">
                            <img src="/todos/ilustracao-refiliacao-mobile.png" alt="Refiliação" />
                        </Col>
                        <Col>
                            <h1 className="text-text-link">
                                Consultamos aqui e <br className="d-none d-xl-block" />
                                você já foi nosso cliente
                            </h1>
                            <p>
                                <strong>Ótimo ter você de volta!</strong> Em alguns minutos
                                um de nossos atendentes <br className="d-xl-none" /> <strong> entrará em
                                contato via telefone </strong> com mais detalhes.
                            </p>

                            {/* <a href="https://www.cartaodetodos.com.br/nossas-unidades/" target="_blank" className='btn btn-secondary btn-lg'>Buscar unidade</a> */}
                        </Col>
                        <Col className="col-auto d-none d-xl-block">
                            <img src="/todos/ilustracao-refilacao.png" alt="Refiliação" />
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal.Body>
        }
    </Modal>
}
