import { useState } from "react";
import { VoucherService } from "../../servicos";
import { ActionService } from "../../servicos/action.service";
import { dateComparePeriod } from "../../utils/utils";

export const Logic = (props: { form: any, alterarDescontoMensalidade: any, configuration: any, alterarVoucherInvalido: any, alterarDescontoAdesao: any, voucher: any }) => {
    const [voucherValido, alterarVoucherValido] = useState(false);
    const [voucherInvalido, alterarVoucherInvalido] = useState(false);
    const [voucherNaoEncontrado, alterarVoucherNaoEncontrado] = useState(false);

    const [voucherLocalValidacao, alterarVoucherLocalValidacao] = useState("");
    const [temCashbackMensalidade, alteraTtemCashbackMensalidade] = useState(false);
    const [temCashbackAdesao, alterarTemCashbackAdesao] = useState(false);

    const [valorDescontoMensalidade, alterarValorDescontoMensalidade] = useState(0);
    const [valorDescontoAdesa, alterarValorDescontoAdesa] = useState(0);
    const [loading, alterarLoading] = useState(false);

    const [lockField, alterarlockField] = useState(false);

    const buscarVoucher = async (voucherTratado: string) => {
        if (voucherTratado.length === 0) {
            alterarVoucherValido(false);
            alterarVoucherInvalido(false);
            return;
        }

        if (voucherLocalValidacao !== voucherTratado) {
            alterarVoucherLocalValidacao(voucherTratado);
            alteraTtemCashbackMensalidade(false);
            alterarTemCashbackAdesao(false);
            alterarVoucherValido(false);
            var ativarToken = false;

            alterarLoading(true);
            var resp = await VoucherService().buscarVoucher(voucherTratado, props.form);
            if (resp != null && resp.valido && resp.servicos != null) {
                var respMensalidade = resp.servicos.find(x => x.id === (props.configuration.linkAfiliado ? 1 : 18));
                var respAdesao = resp.servicos.find(x => x.id === (props.configuration.linkAfiliado ? 4 : 19));

                if (respMensalidade !== null && respMensalidade !== undefined) {
                    if (respMensalidade.possuiDesconto) {
                        props.alterarDescontoMensalidade(respMensalidade!.valorComDesconto!);
                        alterarValorDescontoMensalidade(respMensalidade.valorComDesconto!);
                    }
                    alteraTtemCashbackMensalidade(true);
                    ativarToken = true;
                }

                if (respAdesao !== null && respAdesao !== undefined) {
                    if (respAdesao.possuiDesconto) {
                        props.alterarDescontoAdesao(respAdesao.valorComDesconto!);
                        alterarValorDescontoAdesa(respAdesao.valorComDesconto!);
                    }
                    alterarTemCashbackAdesao(true);
                    ativarToken = true;
                }

                alterarVoucherValido(ativarToken);

                props.alterarVoucherInvalido(!ativarToken);
                alterarVoucherNaoEncontrado(!ativarToken);
                alterarVoucherInvalido(!ativarToken);

                var dataLayer = window.dataLayer || []
                dataLayer.push({
                    'event': 'checkout',
                    'customEvent': 'voucher',
                    'ecommerce': {
                        'checkout': {
                            'actionField': { 'step': 1 },
                            'voucher': [{
                                'voucher': voucherTratado,
                                'brand': 'CDT Checkout',
                            }]
                        }
                    }
                });
            } else {
                ativarToken = false;
                props.alterarVoucherInvalido(true);
                alterarVoucherNaoEncontrado(true);
                alterarVoucherInvalido(true);
            }

            ActionService().sendInvalidField(!ativarToken, 'Cupom não encontrado', props.voucher, "voucher", props.configuration.ga_codigo!);
            alterarLoading(false);
        }
    }

    async function verificarVoucher(value: any = null) {
            var idpv = (getIdpv().has || getCookie('_idpv')) && !getParamsCampanhaPublicitaria() ? true : false;
            props.alterarDescontoMensalidade(0);
            props.alterarDescontoAdesao(0);
            
            /*
             * Funcionalidade Voucher com preenchimento automático
             * - Regra para uso do voucher da campanha
             * 
            */
           
            if (dateComparePeriod(`${process.env.REACT_APP_ENVIRONMENT === "dev" ? '12/18/2023' : '12/20/2023'}`, "01/01/2024").insidePeriod) {
                alterarVoucherNaoEncontrado(false);
                alterarlockField(value === 'NATALCDT2023' ? true : false);
                alterarlockField(value === 'NATALCDT2023' ? true : false);    
            }
            await buscarVoucher(value ? value : props.voucher);

            //Regra para bloquear o uso do voucher da campanha junto com IDPV (link do vendedor)
            // if (dateComparePeriod(`09/${process.env.REACT_APP_ENVIRONMENT === "dev" ? '12' : '15'}/2023`, "09/17/2023").insidePeriod && idpv && value === 'NATALCDT2023') {
            //     alterarVoucherNaoEncontrado(true);
            //     alterarVoucherValido(false);
            // }
            // else {
            //     alterarVoucherNaoEncontrado(false);
            //     await buscarVoucher(value ? value : props.voucher);
            //     alterarlockField(value === 'NATALCDT2023' ? true : false);
            // }
    }

    return {
        voucherValido, voucherInvalido, verificarVoucher, valorDescontoMensalidade, valorDescontoAdesa, voucherNaoEncontrado, temCashbackMensalidade, temCashbackAdesao, loading, lockField
    };
}