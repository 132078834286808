import { useLocation, useNavigate } from "react-router-dom";
import { Configuration } from "../../modelos";
import { useEffect, useState } from "react";
import { setEventPageView, dateComparePeriod } from "../../utils/utils";
import { CheckoutDTO } from "../../dto";

export const Logic = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [modalShow, setModalShow] = useState(false);
    const [modalDiaNacionalSaudeShow, setModalDiaNacionalSaudeShow] = useState(false);
    const [modalOutubroRosaShow, setModalOutubroRosaShow] = useState(false);
    const [modalBlackFridayShow, setModalBlackFridayShow] = useState(false);


    const configuration: Configuration = location.state !== null && (location.state as any).configuration !== null && (location.state as any).configuration !== undefined ? (location.state as any).configuration : new Configuration();
    const filiado: any = location.state !== null && (location.state as any).filiado !== null && (location.state as any).filiado !== undefined ? (location.state as any).filiado : {};
    const dto: CheckoutDTO = location.state !== null && (location.state as any).dto !== null && (location.state as any).dto !== undefined ? (location.state as any).dto : new CheckoutDTO();

    if (location.state === null) {
        window.location.replace("/" + window.location.search);
    }

    const modalSazonalidade = () => {
        setModalBlackFridayShow(dateComparePeriod(`${process.env.REACT_APP_ENVIRONMENT === "dev" ? '11/01/2023' : '11/24/2023'}`, "11/27/2023").insidePeriod);
    }

    function init() {
        (document.getElementById("favicon") as any).href = configuration.favicon;
        setEventPageView(window.location.pathname);
        modalSazonalidade();
    }

    const handleSave = () => {
        navigate("/dependentes-sucesso", { state: { configuration } });
    }


    return { 
        init, 
        configuration, 
        modalDiaNacionalSaudeShow, 
        setModalDiaNacionalSaudeShow,
        modalOutubroRosaShow, 
        setModalOutubroRosaShow,
        modalShow, 
        setModalShow, 
        modalBlackFridayShow,
        setModalBlackFridayShow,
        handleSave, 
        filiado, 
        dto 
    };
}