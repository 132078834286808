import { useLayoutEffect, useState } from 'react';
import { dateComparePeriod, doubleToString, focusOn } from '../../utils/utils';
import { Button, Col, Row } from 'react-bootstrap';
import InputMask from "react-input-mask";
import { Logic } from './logic';

import { HeaderComponente } from '../../componentes/header.component';
import { FooterComponente } from '../../componentes/footer.component';
import { VoucherComponente } from '../../componentes/voucher';
import InputComponent from '../../componentes/input.component';
import { Loading } from '../../componentes/loading.component';
import { CarouselComponent } from '../../componentes/carousel';

import { ReactComponent as IconCep } from '../../assets/icons/icon-cep.svg';
import { ModalTermosComponenteSergipe } from '../../componentes/modal_termos.componente_sergipe';
import { ModalTermosComponente } from '../../componentes/modal_termos.componente';

export default function Inicio({ logic = Logic() }) {
  useLayoutEffect(() => logic.init(), [logic]);

  const [showMeOne, setShowMeOne] = useState(true);

  return (
    <>
      <main>
        <div className="app-container container-fluid">
          <Row className="content-app vh-100">
            <Col className="banner-col px-0 bg-primary col-sm-10">
              <HeaderComponente configuration={logic.configuration} bgSolido={false} class={'page-inicio fixed-top px-0 position-absolute'} />
              <div className='absolute'>
                <CarouselComponent />
              </div>
            </Col>

            {
              showMeOne ? (
                <>
                  <div className="message-col d-flex justify-content-center position-initial d-xl-none">
                    <Row className={`content-start pt-xl-5 ${logic.mensalidade > 0 ? '' : 'shimmer'}`}>

                      <Col className="mx-auto pt-4 pt-xl-0">
                        <div className='card-container pt-2 justify-content-between'>
                          <div className='d-flex flex-column justify-content-center align-items-center'>

                            <div className='card-plans-2 card justify-content-between text-uppercase'>

                              {dateComparePeriod(`${process.env.REACT_APP_ENVIRONMENT === "dev" ? '11/01/2023' : '11/24/2023'}`, "11/27/2023").insidePeriod && (
                                <span className={`blackfriday ${logic.mensalidade > 0 ? '' : 'shimmer'}`}></span>
                              )}

                              <h5>Mensalidade <br /> de apenas</h5>

                              <div className="d-flex align-items-center pt-1">
                                <span className="currency bold">R$</span>
                                <span className={`value ${logic.mensalidade > 0 ? '' : 'shimmer'} `}>
                                  {
                                    doubleToString(
                                      logic.mensalidade
                                    ).split(',')[0]
                                  }
                                </span>

                                <span className='currency bold'> ,{doubleToString(
                                  logic.mensalidade
                                ).split(',')[1]}</span>

                              </div>
                              <div className="title text-uppercase">
                                para toda a família
                              </div>
                            </div>
                          </div>

                          <div className='d-flex flex-column justify-content-center align-items-center'>

                            <div className='card-plans-larger card justify-content-between ml-2'>
                              <h5 className='text-uppercase'>Consultas</h5>
                              <div className='d-flex align-items-center justify-content-between w-100 h-50'>
                                <span className="title">Clínica <br /> geral </span>
                                <div>
                                  <span className="currency">R$
                                    <div className="value">
                                      {logic.configuration?.prices?.clinico_geral}</div>
                                  </span>
                                </div>
                              </div>

                              <div className='d-flex align-items-center justify-content-between w-100 h-50'>
                                <span className="title">Demais <br /> áreas</span>
                                <div>
                                  <span className="currency">R$
                                    <div className="value">
                                      {logic.configuration?.prices?.demais}</div>
                                  </span>
                                </div>
                              </div>
                            </div>

                            <p className='description-custom lato mt-1'>Auxílio-funeral para o titular</p>
                          </div>
                        </div>

                        <Row>
                          <Col>
                            <div className="button-start mt-n2 p-0 d-xl-grid d-grid gap-2 mb-4 mb-xl-3">
                              {
                                logic.mensalidade > 0 ?
                                  <Button
                                    id="btnSubmit"
                                    variant="secondary"
                                    size="lg"
                                    type="submit"
                                    onClick={() => { setShowMeOne(false) }}
                                    className="text-white"
                                  >
                                    SOLICITE AGORA
                                  </Button>
                                  :
                                  <div className="shimmer" />
                              }
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </>) : (
                <>
                  <Col className="message-col-expansive d-xl-none justify-content-center">
                    <Row className="content-start pt-xl-5">
                      <Col className="mx-auto pt-5 pt-xl-0">
                        <h1 className="title text-primary fw-bold text-uppercase text-center">Solicite
                          <br className='d-none d-xl-block' /> o seu cartão
                        </h1>

                        <div className='text-center pt-2 pt-xl-0'>
                          <p className="text-description text-center mt-2 mt-md-4 ">
                            Com o Cartão de TODOS, sua família passa por consultas, dentistas e faz
                            exames na hora que precisa, pelo preço que pode pagar. E você ainda tem
                            acesso a descontos em educação e lazer.
                          </p>
                        </div>


                        <div className="row">
                          <div className="col-12">
                            <VoucherComponente configuration={logic.configuration}
                              getForm={logic.getForm} voucher={logic.voucher} alterarVoucher={logic.alterarVoucher}
                              descontoMensalidade={logic.descontoMensalidade} descontoAdesao={logic.descontoAdesao}
                              alterarDescontoMensalidade={logic.alterarDescontoMensalidade} alterarDescontoAdesao={logic.alterarDescontoAdesao}
                              alterarVoucherInvalido={logic.alterarVoucherInvalido} alterarVoucherDescription={logic.alterarVoucherDescription} />
                          </div>

                          <div className="col-12">
                            <InputComponent
                              title="CEP"
                              type="text"
                              placeholder="Digite seu CEP para iniciar"
                              value={logic.cep}
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) => logic.alterarCep(value => value = event.target.value)}
                              onBlur={() => { logic.verificarCep() }}
                              isValid={logic.cepValido}
                              isInvalid={logic.cepInvalido}
                              enterKeyHint="next"
                              onKeyDown={(event: React.KeyboardEvent<Element>) => focusOn(event, "btnSubmit")}
                              as={InputMask}
                              mask="99999-999"
                              errorMessage={logic.cepMensagemErro}
                              loading={logic.loadingCep}
                              icon={<IconCep />}
                              required />
                          </div>
                        </div>


                        <Row>
                          <Col>
                            <div className="button-start d-xl-grid d-grid gap-2 mb-4 mb-xl-3 pt-2">
                              {
                                logic.load ?
                                  <div className="submit-loader mx-auto">Loading...</div>
                                  :
                                  <Button
                                    id="btnSubmit"
                                    variant="secondary"
                                    size="lg"
                                    type="submit"
                                    onClick={logic.nextPage}
                                    className="text-white"
                                    disabled={logic.mensalidade && logic.cepValido && logic.respCep.franquiaId ? false : true}
                                  >
                                    PRÓXIMO PASSO
                                  </Button>
                              }
                            </div>

                            <div className="mt-xl-4">
                              <small className="text-muted text-center">
                                * Na primeira mensalidade será cobrada a taxa de
                                <br />
                                emissão do cartão no valor de R${' '}
                                {doubleToString(logic.mensalidade)}.
                              </small>
                              <Row className="footer-content ms-xl-1">
                                <footer className="use-banner-footer bg-footer mt-3 inicio">
                                  <FooterComponente
                                    configuration={logic.configuration}
                                    divideTela={true}
                                  />
                                </footer>
                              </Row>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </>)
            }



            <Col className="d-none message-col d-xl-flex justify-content-center">
              <Row className="content-start pt-xl-5">
                <Col className="mx-auto pt-5 pt-xl-0">
                  <h1 className="title text-primary fw-bold text-uppercase text-center">Solicite
                    <br className='d-none d-xl-block' /> o seu cartão
                  </h1>

                  <div className='text-center pt-2 pt-xl-0'>
                    <p className="text-description text-center mt-2 mt-md-4 ">
                      Com o Cartão de TODOS, sua família passa por consultas, dentistas e faz
                      exames na hora que precisa, pelo preço que pode pagar. E você ainda tem
                      acesso a descontos em educação e lazer.
                    </p>
                  </div>

                  <div className='card-container justify-content-between'>
                    <div className="card-plans card text-uppercase">

                      {dateComparePeriod(`${process.env.REACT_APP_ENVIRONMENT === "dev" ? '11/01/2023' : '11/24/2023'}`, "11/27/2023").insidePeriod && (
                        <span className={`blackfriday ${logic.mensalidade > 0 ? '' : 'shimmer'}`}></span>
                      )}

                      <h5>Mensalidade <br /> de apenas</h5>

                      <div className="d-flex align-items-center">
                        <span className="currency bold">R$</span>
                        <span className={`value ${logic.mensalidade > 0 ? '' : 'shimmer'}`}>
                          {
                            doubleToString(
                              logic.mensalidade
                            ).split(',')[0]
                          }
                        </span>
                        <span className='currency bold'> ,{doubleToString(
                          logic.mensalidade
                        ).split(',')[1]}</span>

                      </div>
                      <div className="description bold text-uppercase">
                        para toda a família
                      </div>
                    </div>

                    <div className='d-flex flex-column justify-content-center align-items-center'>

                      <div className='card-plans-larger card justify-content-between'>
                        <h5 className='text-uppercase'>Consultas</h5>
                        <div className='d-flex align-items-center justify-content-between w-100'>
                          <span className="title">Clínica <br /> geral </span>
                          <div>
                            <span className="currency">R$
                              <div className={`value ${logic.mensalidade > 0 ? '' : 'shimmer'}`}>
                                {logic.configuration?.prices?.clinico_geral}</div>
                            </span>
                          </div>
                        </div>

                        <div className='d-flex align-items-center justify-content-between w-100'>
                          <span className="title">Demais <br /> áreas médicas </span>
                          <div>
                            <span className="currency">R$
                              <div className={`value ${logic.mensalidade > 0 ? '' : 'shimmer'}`}>
                                {logic.configuration?.prices?.demais}</div>
                            </span>
                          </div>
                        </div>
                      </div>

                      <p className='text-link lato mt-1'>Auxílio-funeral para o titular</p>
                    </div>
                  </div>


                  <div className="row">
                    <div className="col-12">
                      <VoucherComponente configuration={logic.configuration}
                        getForm={logic.getForm} voucher={logic.voucher} alterarVoucher={logic.alterarVoucher}
                        descontoMensalidade={logic.descontoMensalidade} descontoAdesao={logic.descontoAdesao}
                        alterarDescontoMensalidade={logic.alterarDescontoMensalidade} alterarDescontoAdesao={logic.alterarDescontoAdesao}
                        alterarVoucherInvalido={logic.alterarVoucherInvalido} alterarVoucherDescription={logic.alterarVoucherDescription} />
                    </div>

                    <div className="col-12">
                      <InputComponent
                        title="CEP"
                        type="text"
                        placeholder="Digite seu CEP para iniciar"
                        value={logic.cep}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => logic.alterarCep(value => value = event.target.value)}
                        onBlur={() => { logic.verificarCep() }}
                        isValid={logic.cepValido}
                        isInvalid={logic.cepInvalido}
                        enterKeyHint="next"
                        onKeyDown={(event: React.KeyboardEvent<Element>) => focusOn(event, "btnSubmit")}
                        as={InputMask}
                        mask="99999-999"
                        errorMessage={logic.cepMensagemErro}
                        loading={logic.loadingCep}
                        icon={<IconCep />}
                        required />
                    </div>
                  </div>


                  <Row>
                    <Col>
                      <div className="button-start d-xl-grid d-grid gap-2 mb-4 mb-xl-3 pt-2">
                        {
                          logic.load ?
                            <div className="submit-loader mx-auto">Loading...</div>
                            :
                            <Button
                              id="btnSubmit"
                              variant="secondary"
                              size="lg"
                              type="submit"
                              onClick={logic.nextPage}
                              className="text-white"
                              disabled={logic.mensalidade && logic.cepValido && logic.respCep.franquiaId ? false : true}
                            >
                              PRÓXIMO PASSO
                            </Button>
                        }
                      </div>

                      <div className="mt-xl-4">
                        <small className="text-muted text-center">
                          * Na primeira mensalidade será cobrada a taxa de
                          <br />
                          emissão do cartão no valor de R${' '}
                          {doubleToString(logic.mensalidade)}.
                        </small>
                        <Row className="footer-content ms-xl-1">
                          <footer className="use-banner-footer bg-footer mt-3 inicio">
                            <FooterComponente
                              configuration={logic.configuration}
                              divideTela={true}
                            />
                          </footer>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </main>
    </>
  );
}
