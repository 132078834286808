import { CheckoutDTO, FiliacaoResponseDTO } from "../dto";
import axiosInstance from "../utils/axiosInstance";

export const FiliacaoService = () => {
    const sendFiliacao = async (data: CheckoutDTO, token: string) => {
        try {
            data.token = token;
            var response = await axiosInstance.post<FiliacaoResponseDTO>(`/filiacao`, JSON.stringify(data));
            return response.data;
        } catch (error) {
            return undefined;
        }
    };

    return { sendFiliacao };
}