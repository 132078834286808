export function removeMask(value: string) {
    return value.replace(/\./g, "").replace(/_/g, "").replace(/-/g, "").replace(/\(/g, "").replace(/\)/g, "").replace(/ /g, "").replace(/\//g, "");
}

export function validarCPF(cpf: any) {
    if (typeof cpf !== 'string') return false
    cpf = cpf.replace(/[^\d]+/g, '')
    if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) return false
    cpf = cpf.split('').map((el: any) => +el)
    const rest = (count: any) => (cpf.slice(0, count - 12)
        .reduce((soma: any, el: any, index: any) => (soma + el * (count - index)), 0) * 10) % 11 % 10
    return rest(10) === cpf[9] && rest(11) === cpf[10]
}

export function varlidarEmail(email: string) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
}

export const focusOn = (event: React.KeyboardEvent<Element>, elementId: string) => {
    if (event.key === 'Enter')
        document.getElementById(elementId)?.focus();
}

export function doubleToString(value: number) {
    if (value > 0) {
        var valorString = value.toFixed(2).split(".");

        var firstHalf = valorString[0];
        var secondtHalf = valorString[1] ? (valorString[1].length === 2 ? valorString[1] : `${valorString[1]}0`) : "00";

        return `${firstHalf},${secondtHalf}`
    }
    return "00,00";
}

export function getCardType(number: string) {
    if (!number || number === '') return "";

    var re;
    number = number.replace(/ /g, '');

    // Elo
    re = new RegExp("^4011|438935|45(1416|76)|50(4175|6699|67|90[4-7])|63(6297|6368)");
    if (number.match(re) != null)
        return "elo";

    // Hipercard
    re = new RegExp("^((606282)|(637095)|(637568)|(637599)|(637609)|(637612))");
    if (number.match(re) != null)
        return "hipercard";

    // Diners
    re = new RegExp("^36");
    if (number.match(re) != null)
        return "diners";

    // Diners - Carte Blanche
    re = new RegExp("^30[0-5]");
    if (number.match(re) != null)
        return "diners_carte_blanche";

    // AMEX
    re = new RegExp("^3[47]");
    if (number.match(re) != null)
        return "amex";

    // Mastercard
    // Updated for Mastercard 2017 BINs expansion
    if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(
        number))
        return "master";

    // visa
    re = new RegExp("^4");
    if (number.match(re) != null)
        return "visa";

    // Discover
    re = new RegExp("^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)");
    if (number.match(re) != null)
        return "discover";

    // JCB
    re = new RegExp("^35(2[89]|[3-8][0-9])");
    if (number.match(re) != null)
        return "jcb";

    // Visa Electron
    re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
    if (number.match(re) != null)
        return "visa_electron";

    // Aura
    re = new RegExp("/^(5078\\d{2})(\\d{2})(\\d{11})$/");
    if (number.match(re) != null)
        return "aura";

    return "";
}

export function createCookie(cookieName: string, cookieValue: string, expireInDays: number) {
    var date = new Date();
    date.setDate(date.getDate() + expireInDays);
    document.cookie = cookieName + ' = ' + cookieValue + '; expires = ' + date.toUTCString();
    return true;
};

export function getCookie(cname: string) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export const getIdpv = () => getParam("idpv=");
export const getVoucher = () => getParam("voucher=");
export const getTestAB = () => getParam("cdt=date");
export const getLeadNome = () => getParam("nome=");
export const getLeadEmail = () => getParam("email=");
export const getLeadTelefone = () => getParam("telefone=");
export const getLeadUp18 = () => getParam("up_18=");
export const getLeadAcceptTerms = () => getParam("accept_terms=");
export const getLeadAcceptNotifications = () => getParam("accept_notifications=");
export const getFbPixelDebug = () => getParam("pixel_debug=");
export const getCampanhaPublicitaria = () => getParam("campanha_publicitaria=");

function getParam(value: string) {
    let params = window.location.search.replace("?", "").split("&");

    let tryParam = params.find(x => x.indexOf(value) > -1);
    let hasParam = tryParam ? tryParam.trim().length > 0 : false;
    let param: any;

    if (hasParam) {
        param = params.find(x => x.indexOf(value) > -1)!.replace(value, "");
    }

    return { value: param, has: hasParam };
}

export const getParamsCampanhaPublicitaria = () => {
    const params = window.location.search.replace("?", "").split("&");
    let listToRemove = [
        "idpv=", 
        "voucher=", 
        "cdt=date", 
        "ab=", 
        "from_lead=", 
        "nome=", 
        "email=", 
        "telefone=", 
        "up_18=", 
        "accept_terms=", 
        "accept_notifications=", 
        "pixel_debug="
      ];
    for (let i = 0; i < listToRemove.length; i++) {
        const element = listToRemove[i];
        let getIndexParam = params.findIndex(x => x.indexOf(element) > -1)
        if (getIndexParam > -1) {
            params.splice(getIndexParam, 1);
        }
    }
    return params.length > 1 ? true : false;
};

export const setEventPageView = (pagePath: string) => {
    var origin = window.location.origin;
    var params = window.location.search;
    var pageReferrer = '';
    var gtmLastHistoryIndex = findLastIndex(window.dataLayer,'event','gtm.historyChange');
    
    if ( pagePath === '/' )
        pageReferrer = document.referrer !== '' && document.referrer !== undefined ? document.referrer : window.location.href;
    
    if ( gtmLastHistoryIndex >= 0 )
        pageReferrer = window.dataLayer[gtmLastHistoryIndex]?.['gtm.oldUrl'];

    var hasPage = findLastIndex(window.dataLayer,'customEvent',pagePath);
        
    if ( hasPage < 0 ) {
        var dataLayer = window.dataLayer || [];
        dataLayer.push({
            event: 'page_view',
            customEvent: pagePath,
            page_location: window.location.href,    // URL completa da página acessada contendo parâmetros.
            page_title: 'CDT Checkout',	            // Nome da Página		
            page_referrer : pageReferrer,           // URL completa da página acessada anterior contendo parâmetros.
            page_path: pagePath,	                // Caminho da página sem parâmetros.	
        });
        window.history.pushState({ prevUrl: window.location.href }, document.title);
    }
}

export const findLastIndex = (array, searchKey, searchValue) => {
    var index = array.slice().reverse().findIndex(x => x[searchKey] === searchValue);
    var count = array.length - 1
    var finalIndex = index >= 0 ? count - index : index;
    return finalIndex;
}

export const dateComparePeriod = (startDate: string, endDate: string) => { //"date format: mm/dd/yyyy"
    const today = new Date();
    const date1 = new Date(startDate);
    const date2 = new Date(endDate);
    today.setHours(0,0,0,0);

    const response = {
        insidePeriod: today >= date1 && today <= date2 ? true : false,
        laterDate: today > date2 ? true : false
    };
    
    return response;
};

export function getTotal(valorMensalidade, valorAdesao) {
    return (valorMensalidade !== undefined ? valorMensalidade : 0) + (valorAdesao !== undefined ? valorAdesao : 0);
}

export const hash = (info: string) => {
    const utf8 = new TextEncoder().encode(info);
    if( window.location.host.search('localhost') >= 0 || window.location.host.search('.local') >= 0 ) {
        return utf8;      
    }
    // return crypto.subtle.digest('SHA-256', utf8).then((hashBuffer) => {
    //     const hashArray = Array.from(new Uint8Array(hashBuffer));
    //     const hashHex = hashArray
    //     .map((bytes) => bytes.toString(16).padStart(2, '0'))
    //     .join('');
    //     return hashHex;
    // });
}