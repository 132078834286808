import { Button, Row, Col, Spinner, Form } from "react-bootstrap";
import { doubleToString, focusOn } from '../../utils/utils';
import { useEffect, useLayoutEffect, useRef } from "react";
import { Configuration } from '../../modelos';
import InputMask from "react-input-mask";
import { Logic } from './logic';
import { ModalTermosComponente } from '../modal_termos.componente';
import InputComponent from '../input.component';
import CheckboxComponent from '../checkbox.component';
import { Loading } from '../loading.component';

import { ReactComponent as IconItau } from '../../assets/icons/icon-itau.svg';
import { ReactComponent as IconBradesco } from '../../assets/icons/icon-bradesco.svg';

export const MetodoPagamentoComponente = (props: { configuration: Configuration, handleSubmit: any, formularioPagamento?: any, callRecaptcha?: any, showError?: any, alterarShowError?: any, loading?: any, iniCreditoAberto?: boolean }) => {
    const form = useRef<HTMLFormElement>(null);

    const componentLogic = Logic({ alterarShowError: props.alterarShowError, configuration: props.configuration, callRecaptcha: props.callRecaptcha, form: form, formularioPagamento: props.formularioPagamento, handleSubmit: props.handleSubmit });

    useLayoutEffect(() => {
        if (props.iniCreditoAberto)
            componentLogic.alterarMostrarCredito(props.iniCreditoAberto);
    }, [componentLogic, props.iniCreditoAberto]);

    return (
        <>

            {
                componentLogic.method === 'debito' ?
                    <>
                        <div className="pt-4">
                            <h6 className="form-label">Selecione o seu banco</h6>
                        </div>
                        <div className='btn-group btn-bank-selected mb-4'>
                            <Button name='itau' active={componentLogic.bankName === 'itau'} value='itau' className={`btn-bank onlyLogo rounded`} onClick={() => componentLogic.setBankName('itau')}><IconItau /></Button>
                            <Button name='bradesco' active={componentLogic.bankName === 'bradesco'} className={`btn-bank onlyLogo rounded`} onClick={() => componentLogic.setBankName('bradesco')}><IconBradesco /></Button>
                        </div>

                    </>
                    :
                    <></>

            }

            <div className={componentLogic.bankName !== '' || componentLogic.method === 'credito' ? 'd-block' : 'd-none'}>
                <Form ref={form} noValidate onSubmit={componentLogic.handleSubmit}>
                    <div className="row">
                        <div className="col">
                            <InputComponent
                                id="numeroCartao"
                                title="NÚMERO DO CARTÃO"
                                as={InputMask}
                                mask="9999 9999 9999 9999"
                                type="text"
                                placeholder="0000 0000 0000 0000"
                                value={componentLogic.numeroCartao}
                                onChange={(event) => componentLogic.alterarNumeroCartao(event.target.value)}
                                onBlur={componentLogic.verificarNumeroCartao}
                                isValid={componentLogic.numeroCartaoValido}
                                isInvalid={componentLogic.numeroCartaoInvalido}
                                disabled={false}
                                enterKeyHint="next"
                                onKeyDown={(event: React.KeyboardEvent<Element>) => focusOn(event, "nomeTitular")}
                                errorMessage={componentLogic.numeroCartaoMensagemErro}
                                required />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <InputComponent
                                id="nomeTitular"
                                title="NOME DO CARTÃO"
                                as={InputMask}
                                type="text"
                                placeholder="ex. Maria Lucia Almeida "
                                value={componentLogic.nome}
                                onChange={(event) => componentLogic.alterarNome(event.target.value)}
                                onBlur={componentLogic.verificarNome}
                                isValid={componentLogic.nomeValido}
                                isInvalid={componentLogic.nomeInvalido}
                                disabled={false}
                                enterKeyHint="next"
                                onKeyDown={(event: React.KeyboardEvent<Element>) => focusOn(event, "validadeCartao")}
                                errorMessage={componentLogic.nomeMensagemErro}
                                required />
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-6">
                            <InputComponent
                                id="validadeCartao"
                                title="DATA DE VENCIMENTO"
                                as={InputMask}
                                mask="99/99"
                                type="text"
                                placeholder="10/10"
                                value={componentLogic.validade}
                                onChange={(event) => componentLogic.alterarValidade(event.target.value)}
                                onBlur={componentLogic.verificarValidade}
                                isValid={componentLogic.validadeValido}
                                isInvalid={componentLogic.validadeInvalido}
                                enterKeyHint="next"
                                onKeyDown={(event: React.KeyboardEvent<Element>) => focusOn(event, "cvvCartao")}
                                errorMessage={componentLogic.validadeMensagemErro}
                                required />
                        </div>
                        {
                            componentLogic.bankName !== 'itau' ?
                                <div className="col-6">
                                    <InputComponent
                                        id="cvvCartao"
                                        title="CVV"
                                        as={InputMask}
                                        mask="999"
                                        type="text"
                                        placeholder="123"
                                        value={componentLogic.cvv}
                                        onChange={(event) => componentLogic.alterarCvv(event.target.value)}
                                        onBlur={componentLogic.verificarCvv}
                                        isValid={componentLogic.cvvValido}
                                        isInvalid={componentLogic.cvvInvalido}
                                        disabled={false}
                                        enterKeyHint="next"
                                        onKeyDown={(event: React.KeyboardEvent<Element>) => focusOn(event, "cpf")}
                                        errorMessage={componentLogic.cvvMensagemErro}
                                        required />
                                </div>
                                :
                                <></>
                        }
                    </div>

                    {/* <div className="row">
                        <div className="col">
                            <InputComponent
                                id="cpfCartao"
                                title="CPF"
                                as={InputMask}
                                mask="999.999.999-99"
                                type="text"
                                placeholder="000.000.000-00"
                                value={componentLogic.cpf}
                                onChange={(event) => componentLogic.alterarCpf(event.target.value)}
                                onBlur={componentLogic.verificarCpf}
                                isValid={componentLogic.cpfValido}
                                isInvalid={componentLogic.cpfInvalido}
                                disabled={false}
                                enterKeyHint="next"
                                onKeyDown={(event: React.KeyboardEvent<Element>) => focusOn(event, "cpf")}
                                errorMessage={componentLogic.cpfMensagemErro}
                                required />
                        </div>
                    </div> */}

                    {props.showError ?
                        <p className="mt-3 px-3 text-center">
                            <strong className="text-danger">
                                Ops, ocorreu um erro na transação, tente mais tarde!
                            </strong>
                        </p> : null}

                    <div>
                        <CheckboxComponent
                            title="Termos"
                            value={componentLogic.termos}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => componentLogic.alterarTermos(value => value = event.target.checked)}
                            onBlur={componentLogic.verificarTermos}
                            isInvalid={componentLogic.termosInvalido}
                            errorMessage={componentLogic.termosMensagemErro}
                            required
                            customLabel={<>
                                Li e aceito os <button type="button"
                                    onClick={() => componentLogic.setModalShow(true)}
                                    className="underline text-link-terms"><strong>Termos e Condições</strong></button>
                            </>}
                        />
                    </div>

                    <div className="row">
                        <div className="col">
                            <div className="button-start d-xl-grid d-grid gap-2 mb-4 mb-xl-3 pt-2">
                                {props.loading ?
                                    <div className="submit-loader mx-auto">Loading...</div>
                                    :
                                    <Button variant="secondary" size="lg" type="submit" disabled={props.loading}>
                                        SOLICITAR CARTÃO
                                    </Button>
                                }
                            </div>
                        </div>
                    </div>
                </Form>
                <p className="mt-3">
                    <small className={`text-muted text-center ${props.configuration.prices!.mensalidade! > 0 ? '' : 'shimmer'}`}>
                        * Pagamento mensal de R$ {doubleToString(props.configuration.prices!.mensalidade!)}.<br />
                        ** O pagamento inicial será de R$ {doubleToString(props.configuration.prices!.mensalidade! + props.configuration.prices!.adesao!)}, pois inclui a taxa de adesão no valor de R$ {doubleToString(props.configuration.prices!.adesao!)} mais a primeira mensalidade no valor de R$ {doubleToString(props.configuration.prices!.mensalidade!)}.<br />
                        ***Valor sujeito a alteração devido ao uso de voucher.
                    </small>
                </p>
                {window.innerWidth < 1199.98 ?
                    <Row className="mt-4 mb-3 pt-2">
                        <Col sm={12}>
                            <div className="text-center" dangerouslySetInnerHTML={{ __html: props.configuration.helpTextOthers! }}></div>
                        </Col>
                    </Row> : <></>}
            </div >
            <ModalTermosComponente
                show={componentLogic.modalShow}
                onHide={() => componentLogic.setModalShow(false)}
            />
        </>
    )
}