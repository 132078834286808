export class Configuration {
    linkAfiliado: boolean = false;
    isTestAB: boolean = false;
    id_promotor_lead: string = '';
    tipo_de_vendedor?: string;
    campanha_publicitaria?: boolean;
    valor_total?: number;
    ga_codigo?: string;
    count?: number;
    appName?: string;
    title?: string;
    description?: string;
    meta?: string[];
    logo?: string;
    logoAlt?: string;
    favicon?: string;
    imageVoucher?: string;
    theme?: ConfigurationTheme;
    home?: ConfigurationHome;
    analysis?: ConfigurationAnalysis;
    success?: ConfigurationSuccess;
    dependentsSuccess?: ConfigurationDependentsSuccess;
    helpTextHome?: string;
    helpTextOthers?: string;
    prices?: ConfigurationPrices;
    footerText?: string;
    utilsLinks?: ConfigurationUtilLinks;
}

export class ConfigurationHome {
    carousel?: ConfigurationCarousel[];
}

export class ConfigurationAnalysis {
    image?: string;
    text?: string;
}

export class ConfigurationSuccess {
    image?: string;
    text?: string;
}

export class ConfigurationDependentsSuccess {
    image?: string;
    text?: string;
}

export class ConfigurationPrices {
    clinico_geral?: number;
    demais?: number;
    mensalidade?: number;
    adesao?: number;
}

export class ConfigurationCarousel {
    title?: string;
    titleCarousel?: string;
    titleCarouselWithImage?: string;
    description?: string;
    descriptionCustom?: string;
    descriptionDetails?: string;
    textWithImage?: boolean;
    customItemClass?: string;
    customImgClass?: string;
    imageDesktop?: string;
    imageMobile?: string;
    titleColor?: string;
    textColor?: string;

    height?: number;
    width?: number;
    marginTop?: number;

    campaignStartDate?: string;
    campaignEndDate?: string;
}

export class ConfigurationTheme {
    primary?: string;
    secondary?: string;
    bgFooter?: string;
    bgCarrousel?: string;
    textCarrousel?: string;
    arrowCarrousel?: string;
    bgButton?: string;
    textButton?: string;
    hoverButton?: string;
    bg0800?: string;
    text0800?: string;
    hover0800?: string;
    textPrice?: string;
    borderPrice?: string;
    textDefault?: string;
    textInfo?: string;
    textLink?: string;
    icons?: string;
    separatorVertical?: string;
    separatorHorizontal?: string;
    cardPaymentBorder?: string;
    cardPaymentText?: string;
    labelField?: string;
    bgField?: string;
    borderField?: string;
    borderActive?: string;
    borderError?: string;
    borderSuccess?: string;
    iconsSuccess?: string;
    linkTerms?: string;
    voucherBorder?: string;
    bgCardRegister?: string;
    bgButtonDependent?: string;
    textButtonDependent?: string;
    bgButtonDependentRemove?: string;
    textButtonDependentRemove?: string;
}

export class ConfigurationUtilLinks {
    facebook?: string;
    whatsapp?: string;
    email?: string;
    googleplay?: string;
    appstore?: string;
}
