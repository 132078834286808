import { useEffect, useState } from "react";
import { Col, Row } from 'react-bootstrap';

import { Logic } from "./logic";
import "./style.scss";

import { HeaderComponente } from "../../componentes/header.component";
import { FooterComponente } from "../../componentes/footer.component";
import { CarouselComponent } from "../../componentes/carousel";

import ImgCreditCard from "../../assets/img/creditcard.png";
import ImgLogo from "../../assets/img/logo-CDT.svg";
import { ModalOutubroRosaComponente } from "../../componentes/modal_outubro_rosa.componente";
import { ModalDiaNacionalSaudeComponente } from "../../componentes/modal_dia_nacional_saude.componente";
import { ModalCtaBlackFriday } from "../../componentes/modal_cta_blackfriday";

export default function Sucesso({ logic = Logic() }) {
  useEffect(() => {
    logic.init()
  }, []);

  return (
    <>
      <div className="app-container container-fluid">
        <Row className="vh-100">
          <Col className="banner-col px-0 bg-primary col-sm-12 col">
            <HeaderComponente configuration={logic.configuration} bgSolido={false} class={'page-inicio fixed-top px-0 position-absolute'} />
            <div className="d-none d-xl-block">
              <CarouselComponent />
            </div>
          </Col>

          <Col className="message-col-fixed d-flex justify-content-center d-xl-position-initial">
            <Row className="content-start justify-content-center pt-xl-5">
              <Col className="mx-auto pt-5 pt-xl-0 col">
                <h1 className="title text-primary fw-bold text-uppercase text-center">
                  Bem-vindo ao maior
                  <br />cartão de descontos <br /> do Brasil!
                </h1>

                <div className="creditcard-content container d-flex justify-content-center position-relative pt-3 pt-xl-5">
                  <div className="position-absolute text-center d-md-block shadow rounded-4">
                    <img
                      className="logocard d-inline-block"
                      alt="Imagem do Cartão"
                      src={ImgCreditCard}
                    />
                  </div>
                  <div className="row position-absolute">
                    <div className="creditcard d-flex flex-column align-items-start pt-2 pt-xl-3">
                      <img
                        className="logo d-inline-block"
                        alt="Imagem Logo"
                        src={ImgLogo}
                        width="120"
                        height="60"
                      />
                      <div className="d-flex flex-column align-items-start pt-xl-4">
                        <span>Nome</span>
                        <h1>{logic.dto.nome}</h1>
                      </div>

                      <div className="creditcardDetails d-flex justify-content-between">
                        <div className="d-flex flex-column align-items-start pt-1">
                          <span>Matrícula</span>
                          <h1>{logic.filiado.matricula}</h1>
                        </div>

                        {/* <div className="d-flex flex-column align-items-start pt-1">
                          <span>Data de nascimento</span>
                          <h1>25/07/1983</h1>
                        </div> */}

                      </div>

                      <div className="d-flex flex-column align-items-start pt-1">
                        <span>Unidade</span>
                        <h1 className="text-uppercase">{logic.filiado.franquia}</h1>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="success-message text-center pt-3 position-relative">
                  <p className="lato regular">
                    Filiação realizada com sucesso, você receberá um email com mais instruções e informações. A carteirinha do titular deve ser
                    <br />
                    retirada na unidade do <span className="fw-bolder">Cartão de TODOS.</span> de sua cidade. Na
                    <br />
                    ocasião você também pode adquirir as carteirinhas de seus dependentes. Você receberá a seguir uma lista com todas as
                    <br />
                    unidades do <span className="fw-bolder">Cartão de TODOS.</span>
                  </p>

                  <Col className="pt-4">
                    <h1 className="link-primary">CÓDIGO DA TRANSAÇÃO</h1>

                    <div className="pt-2">
                      <h1 className="code-transation">{logic.filiado.tid}</h1>
                    </div>

                    <div className="pt-2">
                      <span className="description">Comece a usar os descontos, conheça os nossos parceiros</span>
                    </div>

                  </Col>
                </div>

                <Col className="pt-4 text-center px-lx-2">
                  <footer className="use-banner-footer bg-footer mt-3 inicio">
                    <FooterComponente
                      configuration={logic.configuration}
                      divideTela={true}
                    />
                  </footer>
                </Col>
              </Col >
            </Row >
          </Col >
        </Row >
      </div >


      <ModalCtaBlackFriday
        show={logic.modalBlackFridayShow}
        data={logic}
        onHide={() => logic.setModalBlackFridayShow(false)}
      />

      <ModalDiaNacionalSaudeComponente
        show={logic.modalDiaNacionalSaudeShow}
        onHide={() => logic.setModalDiaNacionalSaudeShow(false)}
      />

      <ModalOutubroRosaComponente
        show={logic.modalOutubroRosaShow}
        onHide={() => logic.setModalOutubroRosaShow(false)}
      />
    </>
  )
}
