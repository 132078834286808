import { useEffect } from "react";
import InputMask from "react-input-mask";
import { Row, Col, Button } from "react-bootstrap"

import { Logic } from "./logic"
import { focusOn } from "../../utils/utils";

import { Loading } from "../../componentes/loading.component";
import { CarouselComponent } from "../../componentes/carousel";
import { FooterComponente } from "../../componentes/footer.component";
import { HeaderComponente } from "../../componentes/header.component";

import { ReactComponent as IconArrowProgress } from '../../assets/icons/icon-arrow-progress.svg';
import InputComponent from "../../componentes/input.component";
import { ModalTermosComponenteSergipe } from "../../componentes/modal_termos.componente_sergipe";
import CheckboxComponent from "../../componentes/checkbox.component";

export default function Endereco({ logic = Logic() }) {

  return (
    <main>
      <div className="app-container container-fluid">
        <Row className="vh-100">
          <Col className="banner-col px-0 bg-primary col-sm-12">
            <HeaderComponente configuration={logic.configuration} bgSolido={false} class={'page-inicio fixed-top px-0 position-absolute'} />
            <div className="d-none d-xl-block">
              <CarouselComponent />
            </div>
          </Col>

          <Col className="message-col-fixed d-flex justify-content-center d-xl-position-initial">
            <form onSubmit={logic.handleSubmit}>
              <Row className="content-start pt-xl-5">
                <Col className="mx-auto pt-5 pt-xl-0">
                  <Row className="d-flex justify-content-between">
                    <Col>
                      <Row>
                        <Col sm={12} className="text-center">
                          <h1 className="title text-primary fw-bold text-uppercase ">ENDEREÇO</h1>
                          <h5 className="text-description text-center fw-normal">Esses dados só serão utilizados para fins cadastrais. Nenhum dado obtido pelo nosso site será divulgado ou utilizado de forma indevida.</h5>
                        </Col>
                      </Row>

                      <div className="row">
                        <div className="col-12">

                          <InputComponent
                            title="Rua"
                            id="rua"
                            type="text"
                            placeholder="Novo oriente da boa ventura"
                            value={logic.rua}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => logic.alterarRua(value => value = event.target.value)}
                            onBlur={() => logic.verificarRua()}
                            isValid={logic.ruaValido}
                            isInvalid={logic.ruaInvalido}
                            enterKeyHint="next"
                            onKeyDown={(event: React.KeyboardEvent<Element>) => focusOn(event, "bairro")}
                            errorMessage={logic.ruaMensagemErro}
                            required />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-6">
                          <InputComponent
                            title="Número"
                            id="numero"
                            type="text"
                            mask="999999"
                            as={InputMask}
                            placeholder=""
                            value={logic.numero}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => logic.alterarNumero(value => value = event.target.value)}
                            onBlur={() => logic.verificarNumero()}
                            isValid={logic.numeroValido}
                            isInvalid={logic.numeroInvalido}
                            enterKeyHint="next"
                            onKeyDown={(event: React.KeyboardEvent<Element>) => focusOn(event, "complemento")}
                            errorMessage={logic.numeroMensagemErro}
                            required />
                        </div>
                        <div className="col-6">
                          <InputComponent
                            title="CEP"
                            type="text"
                            placeholder="Digite seu CEP para iniciar"
                            value={logic.cep}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => logic.alterarCep(value => value = event.target.value)}
                            onBlur={() => { logic.verificarCep() }}
                            isValid={logic.cepValido}
                            isInvalid={logic.cepInvalido}
                            enterKeyHint="next"
                            onKeyDown={(event: React.KeyboardEvent<Element>) => focusOn(event, "rua")}
                            as={InputMask}
                            mask="99999-999"
                            errorMessage={logic.cepMensagemErro}
                            loading={logic.loadingCep}
                            required />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <InputComponent
                            title="Complemento"
                            id="complemento"
                            type="text"
                            placeholder=""
                            value={logic.complemento}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => logic.alterarComplemento(value => value = event.target.value)}
                            enterKeyHint="next"
                            isValid={false}
                            isInvalid={false}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12">

                          <InputComponent
                            title="Bairro"
                            id="bairro"
                            type="text"
                            placeholder="Jardim das Oliveiras"
                            value={logic.bairro}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => logic.alterarBairro(value => value = event.target.value)}
                            onBlur={() => logic.verificarBairro()}
                            isValid={logic.bairroValido}
                            isInvalid={logic.bairroInvalido}
                            enterKeyHint="next"
                            onKeyDown={(event: React.KeyboardEvent<Element>) => focusOn(event, "numero")}
                            errorMessage={logic.bairroMensagemErro}
                            required />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-6">
                          <InputComponent
                            title="Cidade"
                            id="cidade"
                            type="text"
                            placeholder=""
                            value={logic.cidade}
                            isValid={(logic.cepValido)}
                            isInvalid={(logic.cepInvalido)}
                            errorMessage={(logic.cepMensagemErro)}
                            disabled={true}
                            required />
                        </div>
                        <div className="col-6">
                          <InputComponent
                            title="UF"
                            id="uf"
                            type="text"
                            placeholder=""
                            value={logic.estado}
                            isValid={(logic.cepValido)}
                            isInvalid={(logic.cepInvalido)}
                            errorMessage={(logic.cepMensagemErro)}
                            disabled={true}
                            required />
                        </div>

                        {logic.estado === 'SE' &&
                          <div>
                            <CheckboxComponent
                              title="Termos"
                              value={logic.termos}
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) => logic.alterarTermos(value => value = event.target.checked)}
                              onBlur={logic.verificarTermos}
                              isInvalid={logic.termosInvalido}
                              errorMessage={logic.termosMensagemErro}
                              required
                              customLabel={<>
                                Li e aceito o <button type="button"
                                  onClick={() => logic.setShowModalSergipe(true)}
                                  className="underline text-link-terms"><strong>contrato de adesão Sergipe</strong></button>
                              </>}
                            />
                          </div>
                        }
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>


              <div className="row">
                <div className="col">
                  <div className="button-start d-xl-grid d-grid gap-2 mb-4 mb-xl-3 pt-2">
                    {
                      logic.loading ?
                        <div className="submit-loader mx-auto">Loading...</div>
                        :
                        <Button
                          id="btnSubmit"
                          variant="secondary"
                          size="lg"
                          type="submit"
                        >
                          PRÓXIMO PASSO
                        </Button>
                    }
                  </div>
                </div>
              </div>

              <div className="row content-progress pt-md-3">

                <div className="col-auto align-self-center">
                  <button className="btn-link text-decoration-none text-primary text-uppercase" onClick={logic.backPage}>
                    <IconArrowProgress />
                    {'VOLTAR'}
                  </button>
                </div>

                <div className="col text-primary fw-bold  text-center">
                  <span className="circle">2</span>
                  <span> de 4</span>
                </div>

                <div className="col-auto"><a className="link-info textinfo text-uppercase">PAGAMENTO</a></div>
              </div>

              <Row className="ms-xl-1">
                <Col className="pt-4 text-center">
                  <footer className="use-banner-footer bg-footer inicio">
                    <FooterComponente
                      configuration={logic.configuration}
                      divideTela={true}
                    />
                  </footer>
                </Col>
              </Row>
            </form>


          </Col>
        </Row>

        <ModalTermosComponenteSergipe
          show={logic.showModalSergipe}
          onHide={() => logic.setShowModalSergipe(false)}
        />

      </div >
    </main >
  )
}

