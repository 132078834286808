import React, { useEffect } from "react";
import { Logic } from "./logic"
import { Row, Col } from "react-bootstrap"
import ReCAPTCHA from "react-google-recaptcha";


import { CarouselComponent } from '../../componentes/carousel';
import { FooterComponente } from "../../componentes/footer.component";
import { HeaderComponente } from "../../componentes/header.component";
import { MetodoPagamentoComponente } from '../../componentes/metodo_pagamento';

import { ReactComponent as IconArrowProgress } from '../../assets/icons/icon-arrow-progress.svg';

export default function Pagamento({ logic = Logic() }) {
  useEffect(() => logic.init(), [logic]);

  const recaptchaRef = React.useRef<any>();
  const verifyRecaptcha = async () => {
    if (logic.captcha === '') {
      const token = await recaptchaRef.current.executeAsync();
      logic.alterarcaptcha(token);
    }
  }

  const pagamento = () => <>
    {logic.configuration !== undefined ?
      <>
        <MetodoPagamentoComponente
          configuration={logic.configuration}
          handleSubmit={logic.handleSubmit}
          formularioPagamento={logic.getForm}
          loading={logic.loading}
          iniCreditoAberto={true}
          callRecaptcha={verifyRecaptcha}
        />
      </>
      : null
    }
  </>;

  return (
    <>
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
      />
      <main>
        <div className="app-container container-fluid">
          <Row className="vh-100">
            <Col className="banner-col px-0 bg-primary col-sm-12 col">
              <HeaderComponente configuration={logic.configuration} bgSolido={false} class={'page-inicio fixed-top px-0 position-absolute'} />
              <div className="d-none d-xl-block">
                <CarouselComponent />
              </div>
            </Col>

            <Col className="message-col-fixed d-flex justify-content-center d-xl-position-initial">
              <Row className="content-start pt-xl-5">
                <Col className="mx-auto pt-5 pt-xl-0">
                  <Row className="d-flex justify-content-between">
                    <Col>
                      <Row>
                        <Col sm={12} className="text-center">
                          <h1 className="title text-primary fw-bold text-uppercase ">
                            DADOS DE <br className="d-none d-lg-block" /> PAGAMENTO
                          </h1>
                          <h5 className="text-description text-center fw-normal">
                            Todos os seus dados de pagamentos serão protegidos por
                            <br className="d-none d-lg-block" />
                            empresa especializada em segurança de dados. Suas
                            <br className="d-none d-lg-block" />
                            informações estarão seguras,
                            <strong>não se preocupe!</strong>
                          </h5>
                        </Col>

                        <Col className={logic.habilitadoEndereco === false ? "disabled" : ""} style={logic.habilitadoPessoa === false ? { cursor: "not-allowed" } : { cursor: "pointer" }}>
                          <Row>
                            <Col>{pagamento()}</Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>

                <div className="row content-progress">
                  <div className="col-auto align-self-center">
                    <button className="btn-link text-decoration-none text-primary text-uppercase" onClick={logic.backPage}>
                      <IconArrowProgress />
                      {'VOLTAR'}
                    </button>
                  </div>

                  <div className="col text-primary fw-bold  text-center">
                    <span className="circle">4</span>
                    <span> de 4</span>
                  </div>

                  <div className="col-auto"><a className="link-info textinfo text-uppercase ">CARTÃO</a></div>
                </div>

                <Row className="ms-xl-1">
                  <Col xl="auto" className="pt-4 text-center px-lx-2">
                    <footer className="use-banner-footer inicio">
                      <FooterComponente
                        configuration={logic.configuration}
                        divideTela={true}
                      />
                    </footer>
                  </Col>
                </Row>
              </Row>
            </Col>
          </Row>
        </div >
      </main >
    </>
  )
}
