import { useState, useEffect } from "react";
import axios from "axios";

import Carousel from 'react-bootstrap/Carousel'
import { CepService, ServicoService } from "../../servicos";
import { ActionService } from "../../servicos/action.service";
import { dateComparePeriod, doubleToString, getCookie, getIdpv, getParamsCampanhaPublicitaria, removeMask, setEventPageView } from "../../utils/utils";
import { useLocation, useNavigate } from "react-router-dom";
import { Cep, Configuration, ServiceValuesModel } from "../../modelos"
import { AccessDTO, CheckoutDTO } from "../../dto";

declare global {
    interface Window {
        ptag_params: any;
    }
}

export const Logic = () => {

    /*
     * Funcionalidade Voucher com preenchimento automático
     * - Regra para uso do voucher da campanha
     * 
    */
    if (dateComparePeriod(`${process.env.REACT_APP_ENVIRONMENT === "dev" ? '12/18/2023' : '12/20/2023'}`, "01/01/2024").insidePeriod) {
        var hasVoucher = getVoucher().has;
        var url = new URL(window.location as any);

        if (!hasVoucher) {
            url.searchParams.set('voucher', 'NATALCDT2023');
        }
        window.history.pushState({}, '', url);
    }

    const location = useLocation();
    const [configuration, alterarConfiguration] = useState(require("./configuration.json") as Configuration);
    const [iniciou, alterarIniciou] = useState(false);
    const dto: CheckoutDTO = location.state !== null && (location.state as any).dto !== null && (location.state as any).dto !== undefined ? (location.state as any).dto : new CheckoutDTO();

    const navigate = useNavigate();

    const titleCarousel = document.getElementById('title-carousel');

    const [carrossel, alterarCarrossel] = useState(configuration.home?.carousel !== undefined ? configuration.home?.carousel[0] : undefined);
    const [valor, alterarValor] = useState<ServiceValuesModel>({});

    const [mensalidade, alterarMensalidade] = useState(configuration.prices!.mensalidade!);
    const [adesao, alterarAdesao] = useState(configuration.prices!.adesao!);
    const [clinicoGeral, alterarClinicoGeral] = useState(configuration.prices!.clinico_geral!);
    const [demais, alterarDemais] = useState(configuration.prices!.demais!);

    const [voucher, alterarVoucher] = useState(dto.voucher ? dto.voucher : "");
    const [voucherDescription, alterarVoucherDescription] = useState("<small class='text-muted'>A <b>taxa de adesão do cartão</b> tem o valor de R$27,50 e poderá ser dividida em 2x.</small>");
    const [voucherInvalido, alterarVoucherInvalido] = useState(true);
    const [descontoMensalidade, alterarDescontoMensalidade] = useState(0);
    const [descontoAdesao, alterarDescontoAdesao] = useState(0);

    const [canFocusCep, alterarCanFocusCep] = useState(false);
    const [cep, alterarCep] = useState(dto.cep ? dto.cep : "");
    const [cepValido, alterarCepValido] = useState(false);
    const [cepInvalido, alterarCepInvalido] = useState(false);
    const [cepMensagemErro, alterarCepMensagemErro] = useState("Campo obrigatório");
    const [respCep, alterarRespCep]: Cep | any = useState({});
    const [loadingCep, alterarLoadingCep] = useState(false);

    const [showModalSergipe, setShowModalSergipe] = useState(false);


    const [utm_Source, setUtm_Source] = useState<String | null | any>(null);
    const [utm_Campaign, setUtm_Campaign] = useState<String | null | any>(null);
    const [utm_Content, setUtm_Content] = useState<String | null | any>(null);
    const [utm_Medium, setUtm_Medium] = useState<String | null | any>(null);
    const [utm_Term, setUtm_Term] = useState<String | null | any>(null);
    const [paramsURL, setParamsURL] = useState<String | null | any>(null);
    

    async function verificarCep() {
        var cepTratado = removeMask(cep);
        var message = cepMensagemErro;
        var invalid = cepInvalido;

        if (cepTratado.length <= 0) {
            invalid = true;
            alterarCepInvalido(true);
            alterarCepValido(false);
            alterarCepMensagemErro("Campo obrigatório");
            return;
        } else {
            if ((respCep && !respCep.franquiaId) || cepTratado != cep) {
                alterarLoadingCep(true);
                invalid = await buscarCep(cepTratado, invalid);
                alterarLoadingCep(false);
            }
        }

        ActionService().sendInvalidField(invalid, message, cepTratado, "cep", configuration.ga_codigo!);
    }

    const buscarCep = async (cepTratado: string, invalid: boolean) => {
        alterarCep(cepTratado);
        var resp: Cep | any = await CepService().buscarCep(cepTratado, getForm());

        invalid = resp !== undefined && resp?.message !== undefined;

        alterarCepValido(resp !== undefined && resp?.message === undefined);
        alterarCepInvalido(resp !== undefined && resp?.message !== undefined);

        if (resp !== undefined && resp?.message === undefined) {
            alterarRespCep(resp);
        }

        if (resp !== undefined && resp?.message !== undefined) {
            alterarCepMensagemErro(resp.message);
        }

        if (window.dataLayer) {
            var indexcepInserted = window.dataLayer?.findIndex((x: { cep: string; }) => x.cep === cepTratado);
            if (indexcepInserted < 0) {
                let dataLayer = window.dataLayer || []
                dataLayer.push({
                    'event': 'cepInserted',
                    'cep': cepTratado,
                    'error': resp !== undefined && resp?.message === undefined ? "Sucesso" : resp.message,
                });
            }
        }

        if (resp !== undefined && resp?.message !== undefined)
            return resp.message;
    }

    function getTotal() {
        var valor1 = descontoMensalidade > 0 ? descontoMensalidade : mensalidade;
        var valor2 = descontoAdesao > 0 ? descontoAdesao : adesao;

        return (valor1 !== undefined ? valor1 : 0) + (valor2 !== undefined ? valor2 : 0);
    }


    function getForm() {
        return {

            //CEP
            cep,
            franquiaId: respCep.franquiaId,
            franquiaNome: respCep.franquiaNome,
            rua: respCep.logradouro,
            bairro: respCep.bairro,
            cidade: respCep.cidade,
            estado: respCep.uf,

            //Outros
            voucher: voucherInvalido ? null : voucher,
            linkAfiliado: configuration.linkAfiliado,
            id_promotor_lead: configuration.id_promotor_lead ? configuration.id_promotor_lead : '',
            campanha_publicitaria: getCampanhaPublicitaria().value,
            ga_codigo: configuration.ga_codigo,

            utm_source: utm_Source,
            utm_campaign: utm_Campaign,
            utm_content: utm_Content,
            utm_medium: utm_Medium,
            utm_term: utm_Term,
            params: paramsURL,
        } as CheckoutDTO;
    }

    function init() {
        // document.head.innerHTML += configuration.meta!;
        (document.getElementById("favicon") as any).href = configuration.favicon;
        setEventPageView(window.location.pathname);

        if (!iniciou) {
            alterarIniciou(true);

            let idpv = getIdpv();

            configuration.isTestAB = false;
            configuration.id_promotor_lead = getCookie('_idpv');
            configuration.campanha_publicitaria = getCampanhaPublicitaria().has;
            configuration.linkAfiliado = (idpv.has || getCookie('_idpv')) && !configuration.campanha_publicitaria ? true : false;

            if (valor.valor === undefined) {
                ServicoService().buscarValorServico(configuration.linkAfiliado && !configuration.campanha_publicitaria ? 1 : 18).then((valorMensalidade: any) => {
                    ServicoService().buscarValorServico(configuration.linkAfiliado && !configuration.campanha_publicitaria ? 4 : 19).then((valorAdesao: any) => {
                        alterarValor(valorMensalidade);
                        alterarMensalidade(valorMensalidade.valor);
                        alterarAdesao(valorAdesao.valor);
                        alterarClinicoGeral(valorMensalidade.clinico_geral);
                        alterarDemais(valorMensalidade.demais);
                        var price = valorMensalidade.valor + valorAdesao.valor;

                        configuration.prices!.mensalidade = valorMensalidade.valor;
                        configuration.prices!.adesao = valorAdesao.valor;
                        configuration.prices!.clinico_geral = valorMensalidade.clinico_geral;
                        configuration.prices!.demais = valorMensalidade.demais;

                        /**
                         * TODO: Melhorar lógica e uso do local storage para quardarmos 
                         * um objeto ao invés de 3 itens.
                         */
                        const PriceAdesaoStorage = JSON.stringify(valorAdesao.valor)
                        localStorage.setItem('@checkout-price-adesao', PriceAdesaoStorage);

                        const PriceMensalStorage = JSON.stringify(valorMensalidade.valor)
                        localStorage.setItem('@checkout-price-mensal', PriceMensalStorage);

                        const PriceTotalStorage = JSON.stringify(price)
                        localStorage.setItem('@checkout-price-total', PriceTotalStorage);

                        if (carrossel !== undefined && carrossel.title!.indexOf("<div class='d-none'>{{valor}}</div>") > -1) {
                            var valorString = doubleToString(valorMensalidade).split(",");
                            var newTitle = carrossel.title!.replaceAll("<div class='d-none'>{{valor}}</div>", `<span class="currency fw-normal">R$</span><span class="value">${valorString[0]}</span><span class="cents">,${valorString[1]}</span>`);
                            carrossel.title = newTitle;
                            alterarCarrossel(JSON.parse(JSON.stringify(carrossel)));
                        }

                        var productView = window.dataLayer?.findIndex((x: { event: string; }) => x.event === "productView");
                        if (productView < 0) {
                            dataLayer.push({
                                event: 'productView',
                                ecommerce: {
                                    detail: {
                                        actionField: { list: 'Home Checkout' },
                                        products: [
                                            {
                                                name: 'Filiação Cartão de Todos',
                                                id: 1,
                                                price: price,
                                                brand: 'CDT Site',
                                            }
                                        ],
                                        items: [
                                            {
                                                item_name: 'Filiação Cartão de Todos',
                                                item_id: 1,
                                                price: price,
                                                item_brand: 'CDT Site',
                                            }
                                        ]
                                    },
                                },
                            });
                        }
                    });
                });

                if (configuration.campanha_publicitaria) {
                    document.cookie = '_idpv=; Max-Age=0';
                    if (configuration.id_promotor_lead) {
                        configuration.id_promotor_lead = '-';
                    }
                }

                alterarConfiguration(configuration);
            }

            if (Carousel.defaultProps?.slide) {
                if (titleCarousel?.classList.contains('active')) {
                    titleCarousel?.classList.remove('active');
                    setTimeout(() => {
                        titleCarousel?.classList.add('active');
                    }, 1);
                } else {
                    titleCarousel?.classList.add('active');
                }
            }

            var count = window.localStorage.getItem("count");
            var localCount = 1;
            if (count) {
                localCount = Number.parseInt(count);
            } else {
                window.localStorage.setItem("count", localCount.toString());
            }

            configuration.count = localCount;
            configuration.ga_codigo = window.dataLayer[0].user.id;

            axios.get('https://geolocation-db.com/json/').then((response: any) => {
                var dto = new AccessDTO();

                dto.user = configuration.ga_codigo;
                dto.data = {
                    ip: response.data.IPv4,
                    count: configuration.count,
                    agent: navigator.userAgent,
                    viewport: '',
                    // viewport: `${window.visualViewport.width}x${window.visualViewport.height}`,
                    query_string: window.location.search,
                    timestamp: new Date().getTime(),
                };

                ActionService().sendAccess(dto);
            });

            if (cep) {
                verificarCep();
            }
        }
    }

    const [load, alterarLoad] = useState(false);

    useEffect(() => {
        const url = new URL(window.location.href);
        const queryParams = url.searchParams;
        const params = window.location.search;

        const utmSource = queryParams.get('utm_source');
        const utmCampaign = queryParams.get('utm_campaign');
        const utmContent = queryParams.get('utm_content');
        const utmMedium = queryParams.get('utm_medium');
        const utmTerm = queryParams.get('utm_term');
        setUtm_Source(utmSource);
        setUtm_Campaign(utmCampaign);
        setUtm_Content(utmContent);
        setUtm_Medium(utmMedium);
        setUtm_Term(utmTerm);
        setParamsURL(params);
    }, [])


    useEffect(() => {
        if (getParam("utm_source=").value === "kwanko") {
            //Kwanko Init
            const script = document.createElement('script');
            script.src = 'https://img.metaffiliation.com/u/24/p77473.js';
            document.head.appendChild(script);

            window.ptag_params = {
                zone: 'homepage'
            };
        }
    }, []);

    const nextPage = async () => {

        if (voucher) {
        }

        verificarCep();

        if (valor && cepValido && respCep.franquiaId) {
            alterarLoad(true);

            navigate("/dados-pessoais" + window.location.search, { state: { configuration, dto: getForm() } });

            // setTimeout(() => {
            //     navigate("/dados-pessoais" + window.location.search, { state: { configuration, dto: getForm() } });
            // }, 5000);
            alterarLoad(false);
        }
    }

    return {
        init, configuration, carrossel, alterarCarrossel, nextPage, valor, load,
        cep, alterarCep, verificarCep, cepValido, cepInvalido, cepMensagemErro, canFocusCep, alterarCanFocusCep, loadingCep,
        voucher, alterarVoucher, alterarVoucherInvalido, voucherDescription, alterarVoucherDescription, voucherInvalido,
        descontoMensalidade, alterarDescontoMensalidade, descontoAdesao, alterarDescontoAdesao, mensalidade, adesao,
        getTotal, getForm, respCep, 
        showModalSergipe, setShowModalSergipe
    };
}
