import { AccessDTO, InvalidFieldDTO, StepDTO } from "../dto/index";
import axiosInstance from "../utils/axiosInstance";

export const ActionService = () => {
    const sendAccess = async (model: AccessDTO) => {
        try {
            await axiosInstance.post(`/action/sendAccess`, JSON.stringify(model));
        } catch (error: any) {
            return;
        }
    };

    const sendStep = async (model: StepDTO, step: number = 1) => {
        try {
            await axiosInstance.post(`/action/sendStep/${step}`, JSON.stringify(model));
        } catch (error: any) {
            return;
        }
    };

    const sendSuccess = async (model: StepDTO) => {
        try {
            await axiosInstance.post(`/action/sendSuccess`, JSON.stringify(model));
        } catch (error: any) {
            return;
        }
    };

    const sendFailed = async (model: StepDTO) => {
        try {
            await axiosInstance.post(`/action/sendFailed`, JSON.stringify(model));
        } catch (error: any) {
            return;
        }
    };

    const sendInvalidField = async (invalid: boolean, error: string, value: string, fieldName: string, ga_codigo: string) => {
        try {
            if (invalid && value.trim().length > 0) {
                var dto = new InvalidFieldDTO()

                dto.user = ga_codigo;
                dto.data = {
                    error: error,
                    value: value,
                    field: fieldName,
                }

                await axiosInstance.post(`/action/sendInvalidField`, JSON.stringify(dto));
            }
        } catch (error: any) {
            return;
        }
    };

    return { sendAccess, sendStep, sendSuccess, sendFailed, sendInvalidField };
}