import { PagamentoDTO } from ".";

export class CheckoutDTO {
    etapalead: string | undefined;
    nome: string | undefined;
    cpf: string | undefined;
    email: string | undefined;
    telefone: string | undefined;
    termos: boolean | undefined;
    termosSmsEmail: boolean | undefined;
    cep: string | undefined;
    franquiaId: number | undefined;
    franquiaNome: number | undefined;
    rua: string | undefined;
    numero: string | undefined;
    complemento: string | undefined;
    bairro: string | undefined;
    cidade: string | undefined;
    estado: string | undefined;
    voucher: string | undefined;
    linkAfiliado: boolean | undefined;
    id_promotor_lead: string | undefined;
    tipo_de_vendedor: string | undefined;
    campanha_publicitaria: string | undefined;
    ga_codigo: string | undefined;
    pagamento: PagamentoDTO | undefined;
    token: string | undefined;

    data_nascimento: string | undefined;
    maior_dezoito: boolean | undefined;

    forma_pagamento_selec: string | undefined;
    test: boolean = false;

    adesao: number | undefined;
    mensalidade: number | undefined;
    total: number | undefined;

    fechou_checkout: boolean | undefined;
    checkout_cdt: string | undefined;

    utm_source: string | undefined;
    utm_campaign: string | undefined;
    utm_content: string | undefined;
    utm_medium: string | undefined;
    utm_term: string | undefined;
    params: string | undefined;

    lead_refiliacao: boolean = false;
}