import { Modal, Button, Container, Row, Col } from "react-bootstrap";
import { ReactComponent as IconFechar } from '../assets/icons/icon-close.svg';

export const ModalOutubroRosaComponente = (props: any) => {
    return (
        <Modal
            {...props}
            className="modal-outubro-rosa"
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Container className="p-0">
                    <Row className="close justify-content-end">
                        <Col className="d-flex align-items-center justify-content-end pointer" onClick={props.onHide}>
                            <strong className="text-white me-2">Fechar</strong>
                            <IconFechar stroke="white" />
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col className="col-auto">
                            <picture>
                                <source media="(max-width: 1199.98px)" srcSet="/todos/banner-outubro-rosa-mobile.png" />
                                <source media="(min-width: 1200px)" srcSet="/todos/banner-outubro-rosa-desktop.png" />
                                <img src="/todos/banner-outubro-rosa-desktop.png" alt="Outubro Rosa." />
                            </picture>
                        </Col>
                    </Row>
                </Container>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <h2 className="title">
                            Tem prevenção, tem Cartão de TODOS.
                        </h2>
                        <p className="description">
                            É hora de se prevenir. E o <strong>Cartão de TODOS</strong> ajuda você. Preparamos consultas e exames nas <br className="d-none d-lg-block" />
                            Clínicas AmorSaúde por ofertas imperdíveis pra cuidar do que é essencial: a sua saúde.
                        </p>
                    </Col>
                </Row>

                <Row className="justify-content-center">
                    <Col className="col-auto">
                        <Button
                            className="btn"
                            href="https://www.amorsaude.com.br/outubro-rosa/?utm_source=ecommerce&utm_campaign=outubrorosa2022-cdt&utm_medium=modal-aquisicao" target="_blank" variant="secondary" size="lg">
                            Agendar Consulta
                        </Button>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <footer>
                            <i className="info">
                                * Condição válida até 31/10/2022, mediante disponibilidade da unidade. Contratação dos pacotes mediante pedido de exames por meio da requisição do médico. AmorSaúde não comercializa CONVÊNIOS nem CARTÕES DE DESCONTO, sendo sua atuação exclusivamente em medicina, odontologia e exames.* Cirurgia já disponível em algumas unidades. Consulte a disponibilidade na sua região. Cartão de desconto não é plano de saúde, não garante e não se responsabiliza pelos serviços oferecidos e pelo pagamento das despesas, nem assegura desconto em todos os serviços obrigatoriamente garantidos por plano de saúde. Tudo o que o cliente usar ou comprar será por ele pago ao prestador, assegurando-se apenas os preços e descontos que constam na relação de empresas e serviços conveniados disponibilizada no site www.cartaodetodos.com.br. Responsáveis técnicos: Alexandre Pimenta CRM 51971 MG | Wanderson Lage CRO 24186 MG | Leonardo Moscovici CRM 121639 SP
                            </i>
                        </footer>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}