import { useEffect, useState } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { doubleToString } from '../utils/utils';

export const ModalTermosComponente = (props: any) => {
    const [adesao, setAdesao] = useState();
    const [mensal, setMensal] = useState();
    const [total, setTotal] = useState();

    useEffect(() => {
        const StorageAdesao = localStorage.getItem('@checkout-price-adesao')
        const StorageMensal = localStorage.getItem('@checkout-price-mensal')
        const StorageTotal = localStorage.getItem('@checkout-price-total')

        setAdesao(JSON.parse(StorageAdesao!));
        setMensal(JSON.parse(StorageMensal!));
        setTotal(JSON.parse(StorageTotal!));
    }, [adesao, mensal, total])

    return <Modal
        {...props}
        size="xl"
        className="modal-sergipe"
        aria-labelledby="contained-modal-title-vcenter"
        centered
    >
        <Modal.Body >
            <Modal.Header closeButton className="border-0">
            </Modal.Header>
            <Row>
                <h1 className="text-uppercase text-center modal-title pt-2 h4 ">Termos e Condições</h1>
                <Col className="content-terms text-center text-lg-start">
                    <p className="p1">Art. 1º – São oferecidos ao ADERENTE, através do presente Contrato de Adesão e seus anexos, convênios com empresas de prestação de serviços nas áreas de saúde (consultas médicas, exames clínicos e tratamentos odontológicos), educação (cursos profissionalizantes e educação escolar) e lazer (hotéis e clubes), a custos reduzidos disponibilizados pelo CNPJ:25.038.155/0001-48, aqui denominado Cartão de TODOS.</p>
                    <p className="p1 ps-5">§ 1º: O Cartão de TODOS não se responsabiliza pela qualidade técnica e profissional dos serviços prestados pelas empresas conveniadas, bem como pelo recebimento dos valores estabelecidos pelas mesmas.</p>
                    <p className="p1 ps-5">§ 2º: O ADERENTE declara ter recebido, no momento da celebração do presente Contrato de Adesão, a lista de todas as empresas conveniadas com o Cartão de TODOS especificado no caput do Art. 1º. Havendo mudança ou substituição do prestador de serviço conveniado, o Cartão de TODOS informa que a atualização da lista de empresas poderá ser acessada em seu sítio eletrônico oficial <a href="http://www.cartaodetodos.com.br/"><span className="s1 ">www.cartaodetodos.com.br</span></a>, bem como através do envio de comunicação eletrônica ao ADERENTE que tiver fornecido ao Cartão de TODOS o seu endereço eletrônico para correspondência no momento da assinatura do contrato.</p>
                    <p className="p1 ps-5">§ 3º: Terá direito ao acesso às empresas conveniadas o ADERENTE e seus familiares (cônjuge e filhos até a idade de 21 anos completos), desde que devidamente inscritos junto ao Cartão de TODOS.</p>
                    <p className="p1 ps-5">§ 4º: Somente o ADERENTE que realizar o pagamento da 1ª mensalidade e que esteja rigorosamente em dia com as suas obrigações financeiras junto ao Cartão de TODOS, terá direito aos serviços e vantagens por ele intermediados.</p>
                    <p className="p1 ps-5">§ 5º: Inclui-se como vantagem ao titular ADERENTE rigorosamente adimplente, maior de 18 anos e com menos de 61 anos no ato da adesão, que tenha quitado o mínimo de 2 (duas) mensalidades, o Auxílio Funeral, seguro de vida que tem por objeto o pagamento de R$1.500,00 (mil e quinhentos reais) no caso de morte natural e acidental, conforme condições gerais e especiais entregues ao ADERENTE neste ato e que também se encontra disponível no <a href="https://cartaodetodos.com.br/auxilio-funeral/"><span className="s1">site</span></a>, com termino de vigência no dia 31/12 do ano de assinatura deste contrato, podendo o referido prazo ser prorrogado a critério do Cartão de TODOS, que comunicará em jornal de grande circulação caso não haja prorrogação.</p>
                    <p className="p1 ps-5">6º: O seguro é garantido pela METROPOLITAN LIFE SEGUROS E PREVIDÊNCIA S.A., Código Susep 0635-1, CNPJ 02.102.498/0001-29, Processo Susep Seguro de Pessoas nº 15414.006580/98-87, e que a aceitação do seguro estará sujeita à análise de risco, e o registro deste plano na Susep não implica, por parte da autarquia, incentivo ou recomendação a sua comercialização.</p>
                    <p className="ps-5">7º: Ao celebrar este Contrato, o ADERENTE fornece ao Cartão de TODOS o seu nome, CPF, endereço, telefone e de seus dependentes e concorda expressamente, nos termos da Lei Geral de Proteção de Dados, no tratamento destes dados para informação aos parceiros do Cartão de TODOS e concessão dos descontos contratados, para realização de cobranças, e para envio de publicidades com descontos de interesse do ADERENTE.</p>
                    <p className="p1">Art. 2º – O ADERENTE obriga-se a pagar ao Cartão de TODOS, a partir da assinatura deste, por si e por seus familiares inscritos, o valor mensal sucessivo de R$ {doubleToString(adesao!).split(',0')[0]} mediante autorização de débito em anexo ao presente contrato, conforme a sua exclusiva escolha (boleto bancário, cobrança em conta de energia, débito em conta de corrente, cartão de crédito ou pagamento direto na sede do Cartão de TODOS).*</p>
                    <p className="p1 ps-5">§ 1º: O ADERENTE pagará, ao Cartão de TODOS, no ato da adesão, a Taxa de Emissão do Cartão de identificação** do ADERENTE no valor de R$ {doubleToString(mensal!).split(',0')[0]} que não se confunde com a 1ª mensalidade.</p>
                    <p className="p1 ps-5">§ 2º: Os pagamentos mensais e sucessivos tratados no caput somente serão devidos, bem como os serviços ora contratados somente serão disponibilizados, após o pagamento da 1ª mensalidade através da forma de arrecadação elegida pelo cliente.</p>
                    <p className="p1 ps-5">§ 3º: É de inteira responsabilidade do ADERENTE, manter o Cartão de TODOS informado sobre quaisquer alterações no cadastro e na forma de cobrança.</p>
                    <p className="p1 ps-5">§ 4º: O reajuste anual da mensalidade ocorrerá em janeiro de cada ano de acordo com o IGPM integral da FGV do ano anterior, sendo informado aos ADERENTES com no mínimo 30 (trinta) dias de antecedência de sua efetivação, por meio do sítio eletrônico <a href="http://www.cartaodetodos.com.br/"><span className="s1">www.cartaodetodos.com.br</span></a>, bem como pelo envio de comunicação eletrônica àqueles clientes que tiverem fornecido o seu endereço eletrônico no momento da assinatura deste contrato.</p>
                    <p className="p1 ps-5">§ 5º: O Cartão de TODOS não se responsabiliza pelas informações prestadas pelo ADERENTE no momento da assinatura do contrato, reservando-se no direito de regresso, em caso de fraude.</p>
                    <p className="p1">Art. 3º – O presente contrato tem validade pelo prazo de 12 (doze) meses, contados do pagamento da 1ª mensalidade conforme Art. 2º, §2º do presente, renovando-se, automaticamente, por prazo indeterminado, caso não haja manifestação expressa em contrário por uma das partes.</p>
                    <p className="p1 ps-5">§ 1º: O ADERENTE poderá rescindir o presente contrato sem quaisquer ônus no prazo de 07 (sete) dias contados da data de sua assinatura em qualquer uma das unidades do Cartão de TODOS.</p>
                    <p className="p1 ps-5">§ 2º: Após a renovação automática do presente contrato por prazo indeterminado, o mesmo poderá ser rescindido por qualquer uma das partes, sem multa, mediante comunicação prévia de 30 (trinta) dias, por escrito, diretamente em qualquer uma das unidades do Cartão de TODOS.</p>
                    <p className="p1 ps-5">§ 3º: No caso de inobservância dos 12 (doze) meses previstos no caput, será devida multa equivalente a 50% sobre o valor da soma das parcelas vincendas.</p>
                    <p className="p1 ps-5">§ 4º: A rescisão do presente instrumento só será efetivada, em qualquer hipótese, mediante o pagamento de todas as mensalidades em atraso.</p>
                    <p className="p1 ps-5">§ 5º: Fica ressalvado o direito de cobrança extrajudicial e judicial, pelo Cartão de TODOS, da(s) mensalidade(s) não quitada(s) e em atraso pelo ADERENTE, acrescido de multa de 2% e juros moratórios de 1% a.m.</p>
                    <p className="p1 ps-5">§ 6º: A suspensão ou cancelamento das cobranças das mensalidades não implica em cancelamento do contrato de adesão ou renúncia do Cartão de TODOS ao seu direito de cobrar a mensalidade do ADERENTE por outro meio.</p>
                    <p className="p1">Art. 4º – Na hipótese de cobrança de mensalidade em conta de energia:</p>
                    <p className="p1 ps-5">§ 1º: O Cartão de TODOS obriga-se a suspender, imediatamente, as cobranças de mensalidades sempre que tal pedido for requisitado pelo consumidor diretamente à concessionária de energia, e desde que tal fatura já não tenha sido emitida antes da solicitação de cancelamento, sob pena de devolução em dobro dos valores arrecadados indevidamente, nos termos da Resolução 581/2013 da ANEEL.</p>
                    <p className="p1 ps-5">§ 2º: Na hipótese de cobrança indevida prevista no parágrafo anterior, o ADERENTE poderá dirigir-se a qualquer uma das unidades do Cartão de TODOS para restituição do valor. A devolução ou crédito em mensalidades futuras, à inteira escolha do consumidor, será processada em até 72 (setenta e duas) horas após a apresentação do comprovante de pagamento da conta de energia elétrica e o protocolo de atendimento da concessionária solicitando o cancelamento da cobrança da mensalidade.</p>
                    <p className="p1 ps-5">§ 3º: O ADERENTE autoriza a alteração/atualização dos dados de titularidade da Conta de Energia junto a Concessionária de Energia.</p>
                    <p className="p1 ps-5">§ 4º: É de inteira e exclusiva responsabilidade do ADERENTE, em hipótese de residir em imóvel locado, comunicar ao proprietário do imóvel sobre as obrigações decorrentes da assinatura deste contrato.</p>
                    <p className="p1">Art. 5º – O ADERENTE se declara esclarecido e de acordo com as cláusulas do presente contrato, bem como está ciente de que o cartão de desconto não é plano de saúde, não garante e não se responsabiliza pelos serviços oferecidos e pelo pagamento das despesas, nem assegura desconto em todos os serviços obrigatoriamente garantidos por plano de saúde. Tudo o que o cliente usar ou comprar será por ele diretamente pago ao prestador, assegurando-se apenas os preços e descontos que constam na relação de empresas e serviços conveniados divulgados no sítio eletrônico <a href="http://www.cartaodetodos.com.br/"><span className="s1">www.cartaodetodos.com.br</span></a>.</p>
                    <p className="p1">Art. 6º – O presente contrato deverá ser interpretado de acordo com as regras previstas no Código de Defesa do Consumidor.</p>
                    <p className="p1">Parágrafo único: Nesta ocasião o Aderente recebe 01 (uma) via do contrato de adesão firmado entre as partes.</p>
                    <p className="p1">Art. 7º – As partes elegem o Foro da Comarca Local, com renúncia expressa de qualquer outro, por mais privilegiado que seja ou venha a ser.</p>
                </Col>
            </Row>
        </Modal.Body>
    </Modal>
}
