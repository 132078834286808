import { CheckoutDTO } from "../dto";
import axiosInstance from "../utils/axiosInstance";

export const LeadService = () => {
    const sendLead = async (data: CheckoutDTO) => {
        try {
            await axiosInstance.post(`/lead`, JSON.stringify(data))
        } catch (error) {
            return undefined;
        }
    };

    return { sendLead };
}