import { Modal, Row, Col } from "react-bootstrap";
import { useState } from "react";

export const ModalCtaBlackFriday = (props: any) => {

    const [stepOne, setStepOne] = useState(true);
    const [stepTwo, setStepTwo] = useState(false);
    const [stepThree, setStepThree] = useState(false);

    const jsonData = {
        cpf: props.data.dto.cpf,
        email: props.data.dto.email,
        matricula: props.data.filiado.matricula
    };

    const base64 = btoa(JSON.stringify(jsonData));

    const nextStep = () => {
        if (stepOne) {
            setStepOne(false);
            setStepTwo(true);
        } else if (stepTwo) {
            setStepTwo(false);
            setStepThree(true);
        } else if (stepThree) {
            setStepThree(false);
        }
    };

    function onStepOne() {
        setStepTwo(false);
        setStepThree(false);
        setStepOne(true);
    }

    function onStepTwo() {
        setStepThree(false);
        setStepOne(false);
        setStepTwo(true);
    }

    function onStepThree() {
        setStepOne(false);
        setStepTwo(false);
        setStepThree(true);
    }


    return <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
        className="modal-ctablackfriday"
    >
        <Modal.Header className="justify-content-end">
            {/* <div
                className="d-flex align-items-center pointer"
                onClick={props.onHide}
            >
                <strong className="text-white">Fechar (X)</strong>
            </div> */}
        </Modal.Header>

        {stepOne && (
            <>
                <Modal.Body className="blackfriday">
                    <Row className="area-refiliacao">
                        <Col className="image col-auto d-xl-none">
                            <img src="/todos/cta-blackfriday/ilustrator01-mobile.png" alt="refilacao" />
                        </Col>
                        <Col className="content">
                            <h1 className="title-refiliacao">
                                Como resgatar a  <br className="d-xl-none" /> carteirinha do <br className="d-none d-xl-block" /> seu time gratuita
                            </h1>
                            <p className="text-refiliacao">
                                Acesse nossa página de seleção times,  <br className="d-xl-none" /> clique em aceite <br className="d-none d-xl-block" /> e pronto, agora já pode  <br className="d-xl-none" /> usar todos benefícios.
                            </p>

                            <div className="btn-container">
                                {/* Fazer a ação depois que concluir o layout */}
                                <a href={`https://campanhas.cartaodetodos.com.br/bf/onboarding-times?data=${base64}`} target="_blank">
                                    <button className="btn-redirect">
                                        Selecionar meu time
                                    </button>
                                </a>
                            </div>

                            <div className="">
                                <button className="btn-transparent" onClick={nextStep}>
                                    Próximo passo
                                </button>
                            </div>

                            <div className="progress-area">
                                <a onClick={onStepOne}>
                                    <img src="/todos/cta-blackfriday/circle-green.png" alt="imagem de um circulo" />
                                </a>

                                <a onClick={onStepTwo}>
                                    <img src="/todos/cta-blackfriday/circle.png" alt="imagem de um circulo" />
                                </a>

                                <a onClick={onStepThree}>
                                    <img src="/todos/cta-blackfriday/circle.png" alt="imagem de um circulo" />
                                </a>
                            </div>

                        </Col>
                        <Col className="image col-auto d-none d-xl-block">
                            <img src="/todos/cta-blackfriday/ilustrator01.png" alt="refilacao" />
                        </Col>
                    </Row>
                </Modal.Body>

            </>
        )}

        {stepTwo && (
            <>
                <Modal.Body className="blackfriday">
                    <Row className="area-refiliacao">
                        <Col className="image-second col-auto d-xl-none pt-1">
                            <img src="/todos/cta-blackfriday/ilustrator02-mobile.png" alt="refilacao" />
                        </Col>
                        <Col className="content">
                            <h1 className="title-refiliacao">
                                Quero ganhar R$ 35 de cashback
                            </h1>
                            <p className="text-refiliacao">
                                Para resgatar o dinheiro, basta baixar <br className="d-xl-none" /> nosso app e criar  <br className="d-none d-xl-block" />  sua conta, tudo pronto para realizar suas consultas  <br className="d-none d-xl-block" />  e exames
                            </p>

                            <div className="">
                                <a href="https://linktr.ee/appcartaodetodos" target="_blank">
                                    <button className="btn-redirectMedium">
                                        Baixar meu app
                                    </button>
                                </a>
                            </div>  

                            <div>
                                <button className="btn-transparent" onClick={nextStep}>
                                    Próximo passo
                                </button>
                            </div>

                            <div className="progress-areaTwo">
                                <a onClick={onStepOne}>
                                    <img src="/todos/cta-blackfriday/circle.png" alt="imagem de um circulo" />
                                </a>

                                <a onClick={onStepTwo}>
                                    <img src="/todos/cta-blackfriday/circle-green.png" alt="imagem de um circulo" />
                                </a>

                                <a onClick={onStepThree}>
                                    <img src="/todos/cta-blackfriday/circle.png" alt="imagem de um circulo" />
                                </a>
                            </div>

                        </Col>
                        <Col className="image-second col-auto d-none d-xl-block">
                            <img src="/todos/cta-blackfriday/ilustrator02.png" alt="refilacao" />
                        </Col>
                    </Row>
                </Modal.Body>

            </>
        )}

        {stepThree && (
            <>
                <Modal.Body className="blackfriday">
                    <Row className="area-refiliacao">
                        <Col className="image-three col-auto d-xl-none pt-4">
                            <img src="/todos/cta-blackfriday/ilustrator03-mobile.png" alt="refilacao" />
                        </Col>
                        <Col className="content">
                            <h1 className="title-refiliacao">
                                2 meses de cursos  <br className="d-xl-none" /> gratuitos
                            </h1>
                            <p className="text-refiliacao">
                                Acesse a Refuturiza e utilize o  <br className="d-xl-none" /> usuário e senha que foram <br className="d-xl-none" /> enviados para o seu e-mail!
                            </p>

                            <div>
                                <a href="https://refuturiza.com.br/aluno/acessar" target="_blank">
                                    <button className="btn-redirect">
                                        Acessar a Refuturiza
                                    </button>
                                </a>
                            </div>

                            {/* <div>
                                <button className="btn-transparent" onClick={props.onHide}>
                                    Minha matricula
                                </button>
                            </div> */}

                            <div className="progress-areaThree">
                                <a onClick={onStepOne}>
                                    <img src="/todos/cta-blackfriday/circle.png" alt="imagem de um circulo" />
                                </a>

                                <a onClick={onStepTwo}>
                                    <img src="/todos/cta-blackfriday/circle.png" alt="imagem de um circulo" />
                                </a>

                                <a onClick={onStepThree}>
                                    <img src="/todos/cta-blackfriday/circle-green.png" alt="imagem de um circulo" />
                                </a>
                            </div>

                        </Col>
                        <Col className="image-three col-auto d-none d-xl-block">
                            <img src="/todos/cta-blackfriday/ilustrator03.png" alt="refilacao" />
                        </Col>
                    </Row>
                </Modal.Body>

            </>
        )}

    </Modal>
}
