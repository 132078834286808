import { BrowserRouter, Route, Routes as DomRoutes } from 'react-router-dom';
import Pagamento from './paginas/pagamento';
import Analise from './paginas/analise';
import Sucesso from './paginas/sucesso';
import Inicio from './paginas/inicio';
import DadosPessoais from './paginas/dados-pessoais';
import Endereco from './paginas/endereco';
import MetodoDePagamento from './paginas/metodo-de-pagamento';

// FLUXO => Início - Pagamento - (Análise) Cartão de crédito análise - Sucesso - Dependentes Sucesso

function Routes() {
    return (
        <BrowserRouter>
            <DomRoutes>
                <Route element={<Inicio />} path="/" />
                <Route element={<DadosPessoais />} path="/dados-pessoais" />
                <Route element={<Endereco />} path="/endereco" />
                <Route element={<MetodoDePagamento />} path="/metodo-de-pagamento" />
                <Route element={<Pagamento />} path="/pagamento" />
                <Route element={<Analise />} path="/analise" />
                <Route element={<Sucesso />} path="/sucesso" />  

                {/* <Route element={<DependentesSucesso />} path="/dependentes-sucesso" /> */}
            </DomRoutes>
        </BrowserRouter>
    )
}

export default Routes