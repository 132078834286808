import { Row, Col } from "react-bootstrap";
import { Configuration } from "../modelos";

export const FooterComponente = (props: { configuration: Configuration, divideTela: boolean }) => {

  return (
    <Row className={"text-center w-100 m-0 footer" + (props.divideTela === false ? " bg-footer" : "")}>
    <Col sm={12} className="">
      <p className="text-muted">
        {props.configuration.footerText}
      </p>
    </Col>
  </Row>
  )
}