import { setEventPageView } from "../../utils/utils";
import { useLocation, useNavigate } from "react-router-dom";
import { Configuration } from "../../modelos";
import { CheckoutDTO } from "../../dto";
import { useState, useEffect } from "react";
import { LeadService } from "../../servicos";
import { AnalyticsService } from "../../servicos/analyticsService";
import { KwankoService } from "../../servicos/kwanko.service";

export const Logic = (test: boolean = false) => {
    // const location = test ? null : useLocation();
    const location = useLocation();
    const [configuration, alterarConfiguration] = useState(location != null && location.state !== null && (location.state as any).configuration !== null && (location.state as any).configuration !== undefined ? (location.state as any).configuration : new Configuration());
    const dto: CheckoutDTO = location.state !== null && (location.state as any).dto !== null && (location.state as any).dto !== undefined ? (location.state as any).dto : new CheckoutDTO();

    if (location === null || location.state === null) {
        window.location.replace("/" + window.location.search);
    }

    const [adesao, setAdesao] = useState();
    const [mensal, setMensal] = useState();
    const [total, setTotal] = useState();

    const [method, setMethod] = useState<string>('');

    useEffect(() => {
        const StorageAdesao = localStorage.getItem('@checkout-price-adesao')
        const StorageMensal = localStorage.getItem('@checkout-price-mensal')
        const StorageTotal = localStorage.getItem('@checkout-price-total')

        setAdesao(JSON.parse(StorageAdesao!));
        setMensal(JSON.parse(StorageMensal!));
        setTotal(JSON.parse(StorageTotal!));
    }, [adesao, mensal, total]);

    const navigate = useNavigate();

    function getForm() {
        return {
            //CEP
            cep: dto.cep,
            franquiaId: dto.franquiaId,
            franquiaNome: dto.franquiaNome,
            rua: dto.rua,
            bairro: dto.bairro,
            cidade: dto.cidade,
            estado: dto.estado,
            numero: dto.numero,
            complemento: dto.complemento,

            //Usuário
            nome: dto.nome,
            cpf: dto.cpf,
            email: dto.email,
            telefone: dto.telefone,
            data_nascimento: dto.data_nascimento,
            maior_dezoito: dto.maior_dezoito,

            forma_pagamento_selec: method,

            //Outros
            termosSmsEmail: dto.termosSmsEmail,
            voucher: dto.voucher,
            linkAfiliado: configuration.linkAfiliado,
            id_promotor_lead: configuration.id_promotor_lead ? configuration.id_promotor_lead : '',
            campanha_publicitaria: getCampanhaPublicitaria().value,
            ga_codigo: configuration.ga_codigo,

            utm_source: dto.utm_source,
            utm_campaign: dto.utm_campaign,
            utm_content: dto.utm_content,
            utm_medium: dto.utm_medium,
            utm_term: dto.utm_term,
            params: dto.params,
        } as CheckoutDTO;
    }

    function init() {
        // document.head.innerHTML += configuration.meta!;
        (document.getElementById("favicon") as any).href = configuration.favicon;
        setEventPageView(window.location.pathname);
    }

    async function backPage() {
        navigate("/endereco" + window.location.search, { state: { configuration, dto: getForm() } });
    }

    async function handleSubmit(metodo: string) {
        var dto = getForm();
        dto.forma_pagamento_selec = metodo;

        await LeadService().sendLead(dto);
        if (window.dataLayer) {
            var indexcheckoutOption = window.dataLayer?.findIndex((x: { event: string; }) => x.event === "checkoutOption");
            if (indexcheckoutOption < 0) {

                var dataLayer = window.dataLayer || []
                dataLayer.push({
                    'event': 'checkoutOption',
                    'ecommerce': {
                        'checkout_option': {
                            'actionField': { 'step': 3, 'option': 'Cartão de Crédito' }
                        }
                    }
                });
            }
        }

        if (metodo) {
            AnalyticsService().SendAnalytics(
                {
                    event: "PaymentMethodSelected",
                    channel: "checkout-adquira",
                    parameters: ConvertMethod(metodo),
                    source: dto.utm_source ? dto.utm_source : null,
                    campaign: dto.utm_campaign ? dto.utm_campaign : null,
                    url: window.location.origin,
                    lead: {
                        name: dto.nome,
                        email: dto.email,
                    }
                }
            );
        }

        navigate("/pagamento" + window.location.search, { state: { configuration, dto: dto } });
    }

    function ConvertMethod(method: string) {
        let newName: any = null;

        switch (method) {
            case 'debito':
                newName = 'debit-account';
                break;

            case 'credito':
                newName = 'credit-card';
                break;

            case 'pix':
                newName = 'pix';
                break;

            default:
                newName = null;
                break;
        }
        return newName;
    }

    useEffect(() => {
        if (dto.termosSmsEmail) {
            const handleBeforeUnload = (e) => {
                e.preventDefault();
                var form = getForm();
                form.fechou_checkout = true;
                LeadService().sendLead(form);

                AnalyticsService().SendAnalytics(
                    {
                        event: "LeaveCheckout",
                        channel: "checkout-adquira",
                        parameters: 'Fechou checkout - Método de pagamento',
                        source: dto.utm_source ? dto.utm_source : null,
                        campaign: dto.utm_campaign ? dto.utm_campaign : null,
                        url: window.location.origin,
                        lead: {
                            name: dto.nome,
                            email: dto.email,
                        }
                    }
                );

                return (e.returnValue = "Tem certeza que deseja sair?");
            };

            window.addEventListener("beforeunload", handleBeforeUnload);

            return () => {
                window.removeEventListener("beforeunload", handleBeforeUnload);
            };
        }
    }, [dto.termosSmsEmail]);

    useEffect(() => {
        //Kwanko Listing
        if (getParam("utm_source=").value === "kwanko") {
            const script = document.createElement('script');
            script.src = 'https://img.metaffiliation.com/u/24/p77473.js';
            document.head.appendChild(script);

            window.ptag_params = {
                zone: 'listing',
                categoryId: '',
                products: ["1"]
            };
        }
    }, []);

    return {
        setMethod,
        configuration,
        init,
        backPage,
        handleSubmit,
        adesao,
        mensal,
        total
    };

}