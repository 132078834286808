import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { CheckoutDTO } from "../../dto";
import { CpfService, EmailService, LeadService, MarketingConversionService } from "../../servicos";
import { ActionService } from "../../servicos/action.service";
import { getCookie, removeMask, validarCPF, varlidarEmail, setEventPageView, createCookie, getFbPixelDebug, hash } from "../../utils/utils";
import { Configuration, Cpf } from "../../modelos";
import { AnalyticsService } from "../../servicos/analyticsService";

export const Logic = () => {
    const location = useLocation();
    const [configuration, alterarConfiguration] = useState(location != null && location.state !== null && (location.state as any).configuration !== null && (location.state as any).configuration !== undefined ? (location.state as any).configuration : new Configuration());
    const dto: CheckoutDTO = location.state !== null && (location.state as any).dto !== null && (location.state as any).dto !== undefined ? (location.state as any).dto : new CheckoutDTO();
    const [carrossel, alterarCarrossel] = useState(configuration.home?.carousel !== undefined ? configuration.home?.carousel[0] : undefined);

    if (location === null || location.state === null) {
        window.location.replace("/" + window.location.search);
    }

    const navigate = useNavigate();
    const [modalShow, setModalShow] = useState(false);

    const [loading, alterarLoading] = useState(false);
    const [bannerRefiliacao, setBannerRefiliacao] = useState(false);

    const [canFocusNumero, alterarCanFocusNumero] = useState(false);

    useEffect(() => {
      // document.head.innerHTML += configuration.meta!;
      (document.getElementById("favicon") as any).href = configuration.favicon;
      setEventPageView(window.location.pathname);

      let dataLayer = window.dataLayer || []
      dataLayer.push({
          event: 'checkout',
          customEvent: 'checkout-step1',
          ecommerce: {
              checkout: {
                  actionField: { step: 1 },
                  products: [
                      {
                          name: 'Filiação Cartão de Todos',
                          id: 1,
                          price: configuration.prices.mensalidade + configuration.prices.adesao,
                          brand: 'CDT Site',
                      }
                  ],
                  items: [
                      {
                          item_name: 'Filiação Cartão de Todos',
                          item_id: 1,
                          price: configuration.prices.mensalidade + configuration.prices.adesao,
                          item_brand: 'CDT Site',
                      }
                  ]
              }
          }
      });

      // Facebook Conversion API -- Event InitiateCheckout
      var payload: any = {
          type: "facebook",
          data: {
              data: [
                  {
                      event_name: "InitiateCheckout",
                      event_time: Math.floor(Date.now() / 1000),
                      user_data: {
                          client_ip_address: sessionStorage.getItem("_ipv4"),
                          client_user_agent: navigator.userAgent,
                          fbp: getCookie('_fbp')
                      },
                      content_category: 'Filiação Cartão de Todos',
                      content_name: 'CDT Site',
                      currency: 'BRL',
                      num_items: 1,
                      value: dto.total
                  }
              ]
          }
      }
      if (getFbPixelDebug().has)
          payload.data.test_event_code = getFbPixelDebug().value;

      MarketingConversionService().sendData(payload);
    }, []);

    const [dataNasc, alterarDataNasc] = useState(dto.data_nascimento ? dto.data_nascimento : "");
    const [dataNascValido, alterarDataNascValido] = useState(false);
    const [dataNascInvalido, alterarDataNascInvalido] = useState(false);
    const [dataNascMensagemErro, alterarDataNascMensagemErro] = useState("Campo obrigatório");
    function verificarDataNasc() {
        var valid = false;
        var splitedData = dataNasc.split("/");
        var minAge = true;

        if (dataNasc.length === 10) {
            var date = new Date(`${parseInt(splitedData[2])}-${parseInt(splitedData[1])}-${parseInt(splitedData[0])}`);
            valid = !isNaN(date.getTime());

            if (valid && getAge(date) < 18) {
                valid = false;
                minAge = false;
            }
        }

        alterarDataNascInvalido(!valid);
        alterarDataNascValido(valid);

        if (dataNasc.length <= 0)
            alterarDataNascMensagemErro("Campo obrigatório");
        else if (!minAge)
            alterarDataNascMensagemErro("Idade não é maior de 18 anos");
        else
            alterarDataNascMensagemErro("Data inválida");

        ActionService().sendInvalidField(!valid, dataNascMensagemErro, dataNasc, "dataNasc", configuration.ga_codigo!);
    }

    function getAge(birthDate: Date) {
        var today = new Date();
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }

    const [maiorDezoito, alterarMaiorDezoito] = useState(dto.maior_dezoito ? dto.maior_dezoito : false);
    const [maiorDezoitoInvalido, alterarMaiorDezoitoInvalido] = useState(false);
    const [maiorDezoitoMensagemErro, alterarMaiorDezoitoMensagemErro] = useState("Campo obrigatório");
    function verificarMaiorDezoito() {
        alterarMaiorDezoitoInvalido(maiorDezoito === false);
        if (!maiorDezoito)
            alterarMaiorDezoitoMensagemErro("Campo obrigatório");

        ActionService().sendInvalidField(maiorDezoito === false, maiorDezoitoMensagemErro, maiorDezoito.toString(), "maiorDezoito", configuration.ga_codigo!);
    }

    const [nome, alterarNome] = useState(dto.nome ? dto.nome : "");
    const [nomeValido, alterarNomeValido] = useState(false);
    const [nomeInvalido, alterarNomeInvalido] = useState(false);
    const [nomeMensagemErro, alterarNomeMensagemErro] = useState("Campo obrigatório");

    function verificarNome() {
        var hasNumber = /\d/;
        var nameRegex = /^(?:[A-Za-z\u00C0-\u017F]+\s){1,5}[A-Za-z\u00C0-\u017F]+$/u;
        var message = nomeMensagemErro;
        var invalid = nome.length < 3 || hasNumber.test(nome) || !nameRegex.test(nome);

        alterarNomeInvalido(invalid);
        alterarNomeValido(nome.length >= 3 && !hasNumber.test(nome) && nameRegex.test(nome));

        if (nome.length <= 0)
            message = "Campo obrigatório";
        else if (hasNumber.test(nome)) {
            message = "Nome inválido";
            alterarNomeInvalido(true);
            alterarNomeValido(false);
        }
        else
            message = "Digite seu nome completo";


        alterarNomeMensagemErro(message);

        ActionService().sendInvalidField(invalid, message, nome, "nome", configuration.ga_codigo!);
    }

    const [cpf, alterarCpf] = useState(dto.cpf ? dto.cpf : "");
    const [cpfValido, alterarCpfValido] = useState(false);
    const [cpfInvalido, alterarCpfInvalido] = useState(false);
    const [cpfMensagemErro, alterarCpfMensagemErro] = useState("Campo obrigatório");
    const [cpfRefiliado, setCpfRefiliado] = useState(false);

    function verificarCpf() {
        var cpfTratado = removeMask(cpf);
        var eValido: boolean = validarCPF(cpfTratado);
        var message = cpfMensagemErro;

        alterarCpf(cpfTratado);
        alterarCpfInvalido(!eValido);
        alterarCpfValido(eValido);

        if (cpfTratado.length <= 0)
            alterarCpfMensagemErro("Campo obrigatório");
        else {
            if (eValido && cpf !== cpfTratado) {
                buscarCpf(cpfTratado, eValido);
            }
            else {
                message = 'CPF inválido';
                alterarCpfMensagemErro(message);
            }
        }

        ActionService().sendInvalidField(!eValido, message, cpfTratado, "cpf", configuration.ga_codigo!);
    }
    const buscarCpf = async (cpfTratado: string, eValido: boolean) => {
        var resp: Cpf | any = await CpfService().validarCpf(cpfTratado);
        setCpfRefiliado(false);

        if (resp !== undefined && resp.cadastrado) {
            // eValido = false;
            // alterarCpfInvalido(true);
            // alterarCpfValido(false);
            // alterarCpfMensagemErro("CPF já cadastrado");

            // eValido = false;
            setCpfRefiliado(true);
        }
        else {
            eValido = true;
        }
    }

    const [email, alterarEmail] = useState(dto.email ? dto.email : "");
    const [emailValido, alterarEmailValido] = useState(false);
    const [emailInvalido, alterarEmailInvalido] = useState(false);
    const [emailMensagemErro, alterarEmailMensagemErro] = useState("Campo obrigatório");


    async function verificarEmail() {
        var eValido: boolean = varlidarEmail(email);
        alterarEmailInvalido((value) => value = !eValido);
        alterarEmailValido((value) => value = eValido);
        var message = emailMensagemErro;

        if (email.length <= 0)
            alterarEmailMensagemErro("Campo obrigatório");
        else {

            if (!eValido) {
                message = "E-mail inválido";
                alterarEmailMensagemErro(message);
            }
            else if (eValido) {
                var emailResp: any = await EmailService().validarEmail(email);

                if (emailResp.idpv && emailResp.idpv !== '-') {
                    if (configuration.campanha_publicitaria) {
                        configuration.id_promotor_lead = '-';
                    }
                    else {
                        configuration.id_promotor_lead = getCookie('_idpv') ? getCookie('_idpv') : emailResp.idpv;
                        configuration.linkAfiliado = true;
                        if (!getCookie('_idpv')) {
                            createCookie('_idpv', emailResp.idpv, 14);
                        }
                    }
                    alterarConfiguration(configuration);
                }

                if (!emailResp.data && !cpfRefiliado) {
                    message = "E-mail já cadastrado";
                    alterarEmailMensagemErro(message);
                    alterarEmailInvalido(true);
                    alterarEmailValido(false);
                    eValido = false;
                }
            }
        }

        ActionService().sendInvalidField(!eValido, message, email, "email", configuration.ga_codigo!);
    }

    const [telefone, alterarTelefone] = useState(dto.telefone ? dto.telefone : "");
    const [telefoneValido, alterarTelefoneValido] = useState(false);
    const [telefoneInvalido, alterarTelefoneInvalido] = useState(false);
    const [telefoneMensagemErro, alterarTelefoneMensagemErro] = useState("Campo obrigatório");
    function verificarTelefone() {
        var telefoneTratado = removeMask(telefone);
        var messasge = telefoneMensagemErro;

        alterarTelefone(telefoneTratado);
        alterarTelefoneInvalido(telefoneTratado.length < 11);
        alterarTelefoneValido(telefoneTratado.length >= 11);

        if (telefoneTratado.length <= 0) {
            messasge = "Campo obrigatório";
            alterarTelefoneMensagemErro(messasge);
        }
        else {
            messasge = "Telefone inválido";
            alterarTelefoneMensagemErro(messasge);
        }

        ActionService().sendInvalidField(telefoneTratado.length < 11, messasge, telefoneTratado, "telefone", configuration.ga_codigo!);
    }

    const [termosSmsEmail, alterarTermosSmsEmail] = useState(false);

    function getForm() {
        return {

            //CEP
            cep: dto.cep,
            franquiaId: dto.franquiaId,
            franquiaNome: dto.franquiaNome,
            rua: dto.rua,
            cidade: dto.cidade,
            bairro: dto.bairro,
            estado: dto.estado,

            //Dados pessoais
            nome,
            cpf,
            email,
            telefone,
            termosSmsEmail,
            data_nascimento: convertDataNasc(dataNasc),
            maior_dezoito: maiorDezoito,

            //Outros
            voucher: dto.voucher,
            linkAfiliado: configuration.linkAfiliado,
            id_promotor_lead: configuration.id_promotor_lead ? configuration.id_promotor_lead : '',
            campanha_publicitaria: getCampanhaPublicitaria().value,
            ga_codigo: configuration.ga_codigo,

            utm_source: dto.utm_source,
            utm_campaign: dto.utm_campaign,
            utm_content: dto.utm_content,
            utm_medium: dto.utm_medium,
            utm_term: dto.utm_term,
            params: dto.params,

            checkout_cdt: 'Adquira',

            lead_refiliacao: cpfRefiliado,
        } as CheckoutDTO;
    }

    function convertDataNasc(dataNasc: string) {
        if (dataNasc.length > 0 && dataNasc.length === 10) {
            var data = dataNasc.split("/");
            return `${data[2]}-${data[1]}-${data[0]}`;
        }
        else
            return dataNasc;
    }

    async function backPage() {
        navigate("/" + window.location.search, { state: { configuration, dto: getForm() } });
    }

    async function handleSubmit(event: any, form?: any) {
        event?.preventDefault();
        event?.stopPropagation();

        verificarNome();
        verificarCpf();
        verificarEmail();
        verificarTelefone();

        if(cpfRefiliado){
            alterarLoading(true);
            setBannerRefiliacao(true);
            var dto = form ? form : getForm() as CheckoutDTO;            
            await LeadService().sendLead(dto);
            return  alterarLoading(false); 
        }

        if (configuration.isTestAB)
            verificarDataNasc();
        else
            verificarMaiorDezoito();

        if ((nomeValido && cpfValido && emailValido && telefoneValido && (configuration.isTestAB ? dataNascValido : maiorDezoito)) || form) {
            alterarLoading(true);

            var dto = form ? form : getForm() as CheckoutDTO;
            dto.linkAfiliado = configuration.linkAfiliado;
            await LeadService().sendLead(dto);
            alterarLoading(false);

            var indexleadCadastro = window.dataLayer?.findIndex((x: { event: string; }) => x.event === "leadCadastro");
            if (indexleadCadastro < 0) {
                let dataLayer = window.dataLayer || []
                dataLayer.push({
                    'event': 'leadCadastro',
                    user: {
                        'id': configuration.ga_codigo,
                        'idpv': configuration.id_promotor_lead ? configuration.id_promotor_lead : '(not set)',
                        'optInTerms': false,
                        'optIn18More': maiorDezoito,
                        'optInNotification': termosSmsEmail,
                        'lead_params': window.location.search.replace('?', ''),
                    }
                });

                // Facebook Conversion API -- Event Lead
                var explodeName = dto.nome.split(/ (.*)/s);
                const fn = await hash(explodeName[0].toLocaleLowerCase());
                const ln = await hash(explodeName[1].toLocaleLowerCase());
                const em = await hash(dto.email.toLocaleLowerCase());
                const ph = await hash(dto.telefone);
                var payload: any = {
                    type: "facebook",
                    data: {
                        data: [
                            {
                                event_name: "Lead",
                                event_time: Math.floor(Date.now() / 1000),
                                user_data: {
                                    fn: fn,
                                    ln: ln,
                                    em: em,
                                    ph: ph,
                                    client_ip_address: sessionStorage.getItem("_ipv4"),
                                    client_user_agent: navigator.userAgent,
                                    fbp: getCookie('_fbp')
                                },
                            }
                        ]
                    }
                }
                if (getFbPixelDebug().has)
                    // payload.data.test_event_code = getFbPixelDebug().value;

                    MarketingConversionService().sendData(payload);
            }

            AnalyticsService().SendAnalytics(
                {
                    event: "GenerateLead",
                    channel: "checkout-adquira",
                    parameters: dto.params ? dto.params : null,
                    source: dto.utmSource ? dto.utmSource : null,
                    campaign: dto.utmCampaign ? dto.utmCampaign : null,
                    url: window.location.origin,
                    lead: {
                        name: dto.nome,
                        email: dto.email,
                        phone: dto.telefone,
                        cpf: dto.cpf,
                        optin18: dto.maior_dezoito,
                        optinContact: dto.termosSmsEmail,
                        acceptTerms: dto.termos
                    }
                }
            );


            navigate("/endereco" + window.location.search, { state: { configuration, dto: getForm() } });
        }

    }

    useEffect(() => {
        if (nomeValido && cpfValido && emailValido && telefoneValido && maiorDezoito && termosSmsEmail) {
            const handleBeforeUnload = (e) => {
                e.preventDefault();
                var form = getForm();
                form.fechou_checkout = true;
                LeadService().sendLead(form);

                AnalyticsService().SendAnalytics(
                    {
                        event: "LeaveCheckout",
                        channel: "checkout-adquira",
                        parameters: 'Fechou checkout - dados pessoais',
                        source: dto.utm_source ? dto.utm_source : null,
                        campaign: dto.utm_campaign ? dto.utm_campaign : null,
                        url: window.location.origin,
                        lead: {
                            name: nome,
                            email: email,
                        }
                    }
                );

                return (e.returnValue = "Tem certeza que deseja sair?");
            };

            window.addEventListener("beforeunload", handleBeforeUnload);

            return () => {
                window.removeEventListener("beforeunload", handleBeforeUnload);
            };
        }
    }, [nomeValido, cpfValido, emailValido, telefoneValido, maiorDezoito, termosSmsEmail]);


    return {
        configuration, backPage, handleSubmit, carrossel, alterarCarrossel,
        nome, alterarNome, verificarNome, nomeValido, nomeInvalido, nomeMensagemErro,
        cpf, alterarCpf, verificarCpf, cpfValido, cpfInvalido, cpfMensagemErro,
        email, alterarEmail, verificarEmail, emailValido, emailInvalido, emailMensagemErro,
        telefone, alterarTelefone, verificarTelefone, telefoneValido, telefoneInvalido, telefoneMensagemErro,
        termosSmsEmail, alterarTermosSmsEmail,

        getForm,
        modalShow, setModalShow, loading,
        dataNasc, alterarDataNasc, verificarDataNasc, dataNascValido, dataNascInvalido, dataNascMensagemErro,
        maiorDezoito, alterarMaiorDezoito, verificarMaiorDezoito, maiorDezoitoInvalido, maiorDezoitoMensagemErro,
        canFocusNumero, alterarCanFocusNumero,

        bannerRefiliacao, setBannerRefiliacao
    };

}