import { useEffect } from "react";
import { Row, Col, Form, Button } from "react-bootstrap"
import InputMask from "react-input-mask";

import { Logic } from "./logic"
import { focusOn } from "../../utils/utils";

import { Loading } from "../../componentes/loading.component";
import InputComponent from "../../componentes/input.component";
import { CarouselComponent } from "../../componentes/carousel";
import CheckboxComponent from "../../componentes/checkbox.component";
import { FooterComponente } from "../../componentes/footer.component";
import { HeaderComponente } from "../../componentes/header.component";

import { ReactComponent as IconArrowProgress } from '../../assets/icons/icon-arrow-progress.svg';
import { ModalRefiliacaoComponente } from "../../componentes/modal_refiliacao.componente";

export default function DadosPessoais({ logic = Logic() }) {

  return (
    <main>
      <div className="app-container container-fluid">
        <Row className="vh-100">
          <Col className="banner-col px-0 bg-primary col-sm-12">
            <HeaderComponente configuration={logic.configuration} bgSolido={false} class={'page-inicio fixed-top px-0 position-absolute'} />
            <div className="d-none d-xl-block">
              <CarouselComponent />
            </div>
          </Col>

          <Col className="message-col-fixed d-flex justify-content-center d-xl-position-initial">
            <Row className="content-start pt-xl-5">
              <Col className="mx-auto pt-5 pt-xl-0">
                <Row className="d-flex justify-content-between">
                  <Col>
                    <Row>
                      <Col sm={12} className="text-center">
                        <h1 className="title text-primary fw-bold text-uppercase ">DADOS PESSOAIS </h1>
                        <h5 className="text-description text-center fw-normal">Esses dados só serão utilizados para fins cadastrais. Nenhum dado obtido pelo nosso site será divulgado ou utilizado de forma indevida.</h5>
                      </Col>

                      <form onSubmit={logic.handleSubmit}>
                        <div className="row">
                          <div className="col-12">
                            <InputComponent
                              autoFocus={true}
                              title="NOME COMPLETO"
                              type="text"
                              placeholder="ex. Maria Lucia Almeida"
                              value={logic.nome}
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) => logic.alterarNome(value => value = event.target.value)}
                              onBlur={logic.verificarNome}
                              isValid={logic.nomeValido}
                              isInvalid={logic.nomeInvalido}
                              enterKeyHint="next"
                              onKeyDown={(event: React.KeyboardEvent<Element>) => focusOn(event, logic.configuration.isTestAB ? "dataNasc" : "cpf")}
                              errorMessage={logic.nomeMensagemErro}
                              required />
                          </div>
                        </div>

                        <InputComponent
                          title="CPF"
                          as={InputMask}
                          type="text"
                          mask="999.999.999-99"
                          placeholder="000.000.000-00"
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => logic.alterarCpf(value => value = event.target.value)}
                          onBlur={() => { logic.verificarCpf() }}
                          value={logic.cpf}
                          isValid={logic.cpfValido}
                          isInvalid={logic.cpfInvalido}
                          enterKeyHint="next"
                          onKeyDown={(event: React.KeyboardEvent<Element>) => focusOn(event, "email")}
                          errorMessage={logic.cpfMensagemErro}
                          required />

                        <InputComponent
                          title="E-MAIL"
                          id="email"
                          type="text"
                          placeholder="ana@gmail.com"
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => logic.alterarEmail(value => value = event.target.value)}
                          onBlur={() => { logic.verificarEmail() }}
                          value={logic.email}
                          isValid={logic.emailValido}
                          isInvalid={logic.emailInvalido}
                          enterKeyHint="next"
                          onKeyDown={(event: React.KeyboardEvent<Element>) => focusOn(event, "telefone")}
                          errorMessage={logic.emailMensagemErro}
                          required />

                        <Row className="justify-content-between">
                          <Col md={12} xl={6} className="md-auto">
                            <InputComponent
                              title="TELEFONE"
                              id="telefone"
                              type="text"
                              mask="(99) 99999-9999"
                              placeholder="(11) 99999-9999"
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) => logic.alterarTelefone(value => value = event.target.value)}
                              onBlur={() => { logic.verificarTelefone() }}
                              value={logic.telefone}
                              isValid={logic.telefoneValido}
                              isInvalid={logic.telefoneInvalido}
                              enterKeyHint="next"
                              onKeyDown={(event: React.KeyboardEvent<Element>) => focusOn(event, logic.configuration.isTestAB ? "dataNasc" : "Maior18")}
                              errorMessage={logic.telefoneMensagemErro}
                              required />
                          </Col>

                          {logic.configuration.isTestAB ?
                            <>
                              <Col md={12} xl={6}>
                                <InputComponent
                                  title="DATA DE NASCIMENTO"
                                  id="dataNasc"
                                  as={InputMask}
                                  type="text"
                                  mask="99/99/9999"
                                  placeholder="Dia / Mês / Ano"
                                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => logic.alterarDataNasc(value => value = event.target.value)}
                                  onBlur={() => { logic.verificarDataNasc() }}
                                  value={logic.dataNasc}
                                  isValid={logic.dataNascValido}
                                  isInvalid={logic.dataNascInvalido}
                                  enterKeyHint="next"
                                  onKeyDown={(event: React.KeyboardEvent<Element>) => focusOn(event, "termosSmsEmail")}
                                  errorMessage={logic.dataNascMensagemErro}
                                  required />
                              </Col>
                            </> : <></>
                          }

                        </Row>

                        {
                          !logic.configuration.isTestAB ?
                            <>
                              <CheckboxComponent
                                title="Maior18"
                                value={logic.maiorDezoito}
                                customLabel={<>Maior de 18 anos</>}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => logic.alterarMaiorDezoito(value => value = event.target.checked)}
                                onBlur={logic.verificarMaiorDezoito}
                                isInvalid={logic.maiorDezoitoInvalido}
                                errorMessage={logic.maiorDezoitoMensagemErro}
                                required
                              />
                            </> : <></>
                        }
                        <CheckboxComponent
                          title="termosSmsEmail"
                          customLabel={<>Eu aceito notificações e contato via <b>SMS</b>, <b>e-mail</b> ou <b>telefone</b></>}
                          value={logic.termosSmsEmail}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => logic.alterarTermosSmsEmail(value => value = event.target.checked)}
                        />

                        <div className="row">
                          <div className="col">
                            <div className="button-start d-xl-grid d-grid gap-2 mb-4 mb-xl-3 pt-2">
                              {
                                logic.loading ?
                                  <div className="submit-loader mx-auto">Loading...</div>
                                  :
                                  <Button
                                    id="btnSubmit"
                                    variant="secondary"
                                    size="lg"
                                    type="submit"
                                    disabled={logic.loading}
                                  >
                                    PRÓXIMO PASSO
                                  </Button>
                              }
                            </div>
                          </div>
                        </div>

                        <div className="row content-progress pt-md-3">

                          <div className="col-auto align-self-center">
                            <button className="btn-link text-decoration-none text-primary text-uppercase" onClick={logic.backPage}>
                              <IconArrowProgress />
                              {'VOLTAR'}
                            </button>
                          </div>

                          <div className="col text-primary fw-bold text-center">
                            <span className="circle">1</span>
                            <span> de 4</span>
                          </div>

                          <div className="col-auto"><a className="link-info textinfo text-uppercase">ENDEREÇO</a></div>
                        </div>

                        <Row className="ms-xl-1">
                          <Col className="pt-2 text-center">
                            <footer className="use-banner-footer inicio">
                              <FooterComponente
                                configuration={logic.configuration}
                                divideTela={true}
                              />
                            </footer>
                          </Col>
                        </Row>
                      </form>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>

        <ModalRefiliacaoComponente
                show={logic.bannerRefiliacao}
                onHide={() => logic.setBannerRefiliacao(false)}
            />
      </div >
    </main >
  )
}