import { useEffect } from "react";
import { Row, Col } from "react-bootstrap"

import { Logic } from "./logic"
import { doubleToString } from '../../utils/utils';

import { CarouselComponent } from "../../componentes/carousel";
import { FooterComponente } from "../../componentes/footer.component";
import { HeaderComponente } from "../../componentes/header.component";

import { ReactComponent as IconCreditCard } from '../../assets/icons/icon-credit-card.svg';
import { ReactComponent as IconArrowProgress } from '../../assets/icons/icon-arrow-progress.svg';

export default function MetodoDePagamento({ logic = Logic() }) {
  useEffect(() => logic.init(), [logic]);

  return (
    <>
      <main>
        <div className="app-container container-fluid">
          <Row className="vh-100">
            <Col className="banner-col px-0 bg-primary col-sm-12">
              <HeaderComponente configuration={logic.configuration} bgSolido={false} class={'page-inicio fixed-top px-0 position-absolute'} />
             <div className="d-none d-xl-block">
                <CarouselComponent />
              </div>
            </Col>

            <Col className="message-col-fixed d-flex justify-content-center d-xl-position-initial">
              <Row className="content-start-small">
                <Col className="mx-auto">
                  <Row className="d-flex justify-content-between">
                    <Col>
                      <Row>
                        <Col sm={12} className="text-center">
                          <h1 className="title text-primary fw-bold text-uppercase ">MÉTODOS DE <br /> PAGAMENTO</h1>
                          <h5 className="text-description text-center fw-normal">Selecione um dos nossos métodos de pagamento</h5>
                        </Col>

                        <Col>
                          <Row>
                            <Col>
                              <div className="credit-card-view d-flex justify-content-center">
                                <div className="px-4">
                                  <button
                                    className="d-flex flex-column align-items-center justify-content-center"
                                    role="button"
                                    type="button"
                                    onClick={() => logic.handleSubmit('credito') }
                                  >
                                    <IconCreditCard />
                                    <strong className="lato bold">Cartão de<br />crédito</strong><span className="lato regular">Pagamento mensal<br />de R$ {
                                      doubleToString(
                                        logic.adesao!
                                      ).split(',0')[0]
                                    }*</span>
                                  </button>
                                </div>

                                <div>
                                  <button
                                    className="d-flex flex-column align-items-center justify-content-center"
                                    role="button"
                                    type="button"
                                    onClick={() => logic.handleSubmit('debito') }
                                  >
                                    <IconCreditCard />
                                    <strong className="lato bold">Cartão de<br />débito</strong><span className="lato regular">Pagamento mensal<br />de R$ {
                                      doubleToString(
                                        logic.adesao!
                                      ).split(',0')[0]
                                    }*</span>
                                  </button>
                                </div>
                              </div>

                              <p className="excerpt text-center ps lato regular mt-5">
                                *O pagamento inicial será no valor de R$
                                {
                                  doubleToString(
                                    logic.total!
                                  ).split('x,00')[0]
                                } pois inclui a
                                <br />
                                taxa de adesão no valor de R$
                                {
                                  doubleToString(
                                    logic.adesao!
                                  ).split(',0')[0]
                                }
                                {' '} mais a primeira
                                <br />
                                mensalidade no valor de R$
                                {
                                  doubleToString(
                                    logic.mensal!
                                  ).split(',0')[0]
                                }
                              </p>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <div className="row content-progress-space  spacetop text-center">
                    <div className="col-auto align-self-center">
                      <button className="btn-link text-decoration-none text-primary text-uppercase" onClick={logic.backPage}>
                        <IconArrowProgress />
                        {'VOLTAR'}
                      </button>
                    </div>

                    <div className="col text-primary fw-bold text-center">
                      <span className="circle">3</span>
                      <span> de 4</span>
                    </div>

                    <div className="col-auto"><a className="link-info textinfo text-uppercase ">PAGAMENTO</a></div>
                  </div>

                  <Row className="footer-content pt-xl-2">
                    <footer className="use-banner-footer bg-footer mt-3">
                      <FooterComponente
                        configuration={logic.configuration}
                        divideTela={true}
                      />
                    </footer>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </div >
      </main >
    </>
  )
}
