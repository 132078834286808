import { useEffect } from "react";
import { Button, Col, Row } from 'react-bootstrap';

import { Logic } from "./logic";
import "./style.scss";

import { CarouselComponent } from "../../componentes/carousel";
import { HeaderComponente } from "../../componentes/header.component";
import { FooterComponente } from "../../componentes/footer.component";

import CardImage from "../../assets/img/analise.svg";

export default function Sucesso({ logic = Logic() }) {
  useEffect(() => {
    logic.init()
  }, [logic]);

  return (
    <>
      <main>
        <div className="app-container container-fluid">
          <Row className="vh-100">
          <Col className="banner-col px-0 bg-primary col-sm-12 col">
              <HeaderComponente configuration={logic.configuration} bgSolido={false} class={'page-inicio fixed-top px-0 position-absolute'} />
              <div className="d-none d-xl-block">
                <CarouselComponent />
              </div>
            </Col>

            <Col className="message-col-fixed d-flex justify-content-center d-xl-position-initial">
            <Row className="content-start pt-xl-5">
                <Col className="mx-auto pt-5 pt-xl-0 text-center">
                  <h1 className="title text-primary fw-bold text-uppercase text-center">
                    Seu pagamento <br className="d-none d-lg-block"/> está <br className="d-block d-lg-none"/> em análise.
                  </h1>

                  <img
                    className="img-analise d-inline-block pt-5 pb-5 pb-lg-0"
                    alt="Imagem Análise"
                    src={CardImage}
                  />

                  <div className="analise-col text-center">
                    <p className="lato regular">
                      Estamos felizes pelo seu interesse em contratar o Cartão de TODOS. Seu pedido foi registrado com sucesso e agora está em análise pelo nosso time. Em breve entraremos em contato com você por e-mail ou telefone.
                      <br />
                      <br />
                      Enquanto aguarda sua aprovação, aproveite para conhecer nossa rede de benefícios a que você terá acesso.
                    </p>

                    <Col className="pt-2">
                      <Button
                        id="btnSubmit"
                        variant="secondary"
                        size="lg"
                        type="submit"
                        className="w-100"
                        href="https://www.cartaodetodos.com.br/beneficios"
                        target="_blank"
                      >
                        BENEFÍCIOS
                      </Button>
                    </Col>
                  </div>

                  <Col className="pt-4 text-center px-lx-2">
                  <footer className="use-banner-footer bg-footer mt-3 inicio">
                    <FooterComponente
                      configuration={logic.configuration}
                      divideTela={true}
                    />
                  </footer>
                  </Col>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </main>
    </>

  )
}
