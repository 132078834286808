import { setEventPageView } from "../../utils/utils";
import { Configuration } from "../../modelos"
import { useState } from "react";

export const Logic = () => {
    const [configuration, alterarConfiguration] = useState(require("../../paginas/inicio/configuration.json") as Configuration);

    const [carrossel, alterarCarrossel] = useState(configuration.home?.carousel !== undefined ? configuration.home?.carousel[0] : undefined);

    function init() {
        // document.head.innerHTML += configuration.meta!;
        (document.getElementById("favicon") as any).href = configuration.favicon;
        setEventPageView(window.location.pathname);
    }

    return {
        init, configuration, carrossel, alterarCarrossel,
    };
}
