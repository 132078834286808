import { useEffect } from 'react';
import { Carousel } from 'react-bootstrap';
import { Logic } from './logic';

import { ConfigurationCarousel } from '../../modelos/configuration';
import { dateComparePeriod } from '../../utils/utils';

export function CarouselComponent({ logic = Logic() }) {
  useEffect(() => logic.init(), [logic]);

  return (
    <Carousel
      fade
      className="carousel-content d-flex  d-xl-block "
      wrap={true}
      touch={true}
      indicators={false}
      interval={null}
      onSelect={(index) =>
        logic.alterarCarrossel(
          logic.configuration.home?.carousel !== undefined
            ? logic.configuration.home?.carousel[index]
            : undefined
        )
      }
    >

      {logic.configuration.home?.carousel?.filter(
        (item: ConfigurationCarousel) =>
          !item.campaignStartDate ||
          (dateComparePeriod(`${item.campaignStartDate}`, `${item.campaignEndDate}`).insidePeriod)
      )
        .map((item) => {
          return (
            <Carousel.Item key={item.imageDesktop} className={`text-center w-100 d-xl-block ${item.customItemClass}`} style={{ marginTop: item.marginTop }}>

              <div className={`${item.textWithImage ? "d-flex justify-content-center" : ""}`}>
                <img
                  className={item.imageMobile !== null ? "d-none d-xl-inline" : ""}
                  src={item.imageDesktop}
                  alt={item.imageMobile !== null ? item.imageMobile : item.imageDesktop}
                  height={item.height}
                  width={item.width}
                  style={
                    { width: item.width ? `${item.width}px` : 'initial' }
                  }
                />
              </div>
              {
                item.textWithImage &&
                <strong className='text-white fw-bold text-center ' dangerouslySetInnerHTML={{ __html: item.titleCarouselWithImage! }} />
              }

              <img
                className={item.imageMobile !== null ? "d-xl-none" : "d-none"}
                src={item.imageMobile}
                alt={item.imageMobile !== null ? item.imageMobile : item.imageDesktop}
                height={item.height}
                width={item.width}
              />

              {
                item.titleCarousel ? (
                  <>
                    <div className='content col-auto'>
                      <div className="area-description text-center">
                        <div className='description'>
                          <strong className='text-white fw-bold text-center' dangerouslySetInnerHTML={{ __html: item.titleCarousel! }}>
                          </strong>
                        </div>

                        {
                          item.description &&
                          <div className='description'>
                            <p className='text text-white pt-2'>{item.description}</p>
                          </div>

                        }

                        {
                          item.descriptionCustom &&
                          <div className='area-description-custom'>
                            <p className='text-white text-center pt-2' dangerouslySetInnerHTML={{ __html: item.descriptionCustom! }}></p>
                          </div>
                        }

                        {
                          item.descriptionDetails &&
                          <p className='text-white text-center pt-1 d-block d-xl-none' dangerouslySetInnerHTML={{ __html: item.descriptionDetails! }}>
                          </p>
                        }

                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )
              }
            </Carousel.Item>
          );
        }
        )}
    </Carousel >
  )
}