import { useEffect, useState } from "react";
import { Button, Navbar, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Configuration } from "../modelos";

import iconPhone from "../assets/icons/icon-phone.svg";

export const HeaderComponente = (props: { configuration: Configuration, bgSolido: boolean, class?: string }) => {
    const navigate = useNavigate();
    const page = window.location.pathname === '/' ? 'inicio' : window.location.pathname.replace('/', '');

    const [logo, alterarLogo] = useState(props.configuration.logo);

    window.onresize = function (event) {
        verifyLogo();
    }

    useEffect(() => verifyLogo());

    const verifyLogo = () => {
        if (props.bgSolido === false) {
            if (window.innerWidth > 1199.98) {
                return alterarLogo(props.configuration.logo);
            }
            else {
                return alterarLogo(props.configuration.logoAlt);
            }
        } else {
            return alterarLogo(props.configuration.logo);
        }
    }

    const openInNewTab = url => {
        window.open(url, '_blank');
    };

    return <>
        {process.env.REACT_APP_ENVIRONMENT === "dev" ?
            <Container fluid className="homolog-message bg-danger text-center ps-md-3 ps-lg-5">
                <small className="text-white ps-lg-3">Site de Homologação</small>
            </Container> : <></>}
        <Navbar className={"px-xl-5 py-6 pt-xl-5 " + props.class + (props.bgSolido ? "bg-white " : "")} data-page={page} >

            <Container className="container-navbar" fluid="xxl">
                <Navbar.Brand>
                    <img
                        className="logo d-inline-block"
                        alt="Imagem Logo"
                        src={logo}
                        onClick={() => openInNewTab('https://cartaodetodos.com.br')}
                    />

                </Navbar.Brand>
                <a href="tel:08007292072">
                    <Button
                        variant="primary"
                        type="button"
                        title="Fale com nossos atendentes"
                        className="btn-sm d-flex align-items-center justify-content-center"
                    >
                        <img src={iconPhone} className="" />
                        <span className="">0800 729 2072</span>
                    </Button></a>
            </Container>
        </Navbar></>
}
