import { Form, FormControl, InputGroup } from "react-bootstrap";
import { doubleToString, getVoucher } from "../../utils/utils";
import { Configuration } from "../../modelos";
import { Logic } from "./logic";
import { useLayoutEffect, useState } from "react";
import InputComponent from "../input.component";

export const VoucherComponente = (props: { configuration: Configuration, onFocus?: any, getForm: any, voucher?: any, alterarVoucher?: any, descontoMensalidade?: any, alterarDescontoMensalidade?: any, descontoAdesao?: any, alterarDescontoAdesao?: any, alterarVoucherInvalido?: any, alterarVoucherDescription?: any }) => {
    const componentLogic = Logic({ alterarDescontoAdesao: props.alterarDescontoAdesao, alterarDescontoMensalidade: props.alterarDescontoMensalidade, alterarVoucherInvalido: props.alterarVoucherInvalido, configuration: props.configuration, form: props.getForm(), voucher: props.voucher });
    const [carregado, alterarCarregado] = useState(false);

    useLayoutEffect(() => {
        if (!carregado) {
            alterarCarregado(true);
            let voucher = getVoucher();
            if (voucher.has && props.voucher === '') {
                props.alterarVoucher(voucher.value);
            }
            componentLogic.verificarVoucher(voucher.value);
        }
    }, [carregado, props, componentLogic]);

    return (
        <>

            <InputComponent
                title="VOUCHER"
                type="text"
                placeholder="Caso possua um voucher insira ele aqui"
                aria-describedby="voucher"
                value={props.voucher}
                onChange={(event) => props.alterarVoucher(value => value = event.target.value.toLocaleUpperCase())}
                onBlur={() => componentLogic.verificarVoucher(props.voucher)}
                isValid={componentLogic.voucherValido}
                isInvalid={componentLogic.voucherInvalido}
                enterKeyHint="next"
                errorMessage={componentLogic.voucherNaoEncontrado || componentLogic.voucherInvalido ? "Voucher inválido" : ""}
                loading={componentLogic.loading}
                icon={<img src={props.configuration.imageVoucher} alt="Imagem Voucher"/>}
                notice={componentLogic.voucherValido ?
                    <small className="text-center">
                        <span className="fw-bold text-primary">Cupom ativo</span> -
                        {props.descontoMensalidade > 0 ? <><span className="voucher-detail text-muted text-center"> R$ {doubleToString(componentLogic.valorDescontoMensalidade)} na primeira mensalidade </span> <br /></> : componentLogic.temCashbackMensalidade ? <span className="voucher-detail text-muted text-center"> Cashback na mensalidade </span> : null}
                        {props.descontoAdesao > 0 ? <span className="voucher-detail text-muted text-center"> R$ {doubleToString(componentLogic.valorDescontoAdesa)} na adesão </span> : componentLogic.temCashbackAdesao ? <span className="voucher-detail text-muted text-center"> Cashback na adesão </span> : null}
                    </small>
                    : <></>}
                disabled={componentLogic.lockField}
                />
        </>
    );
}