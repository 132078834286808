import { CheckoutDTO } from "../dto";
import { Cep } from "../modelos";
import axiosInstance from "../utils/axiosInstance";

export const CepService = () => {
    const buscarCep = async (cep: string, data: CheckoutDTO) => {
        try {
            var response = await axiosInstance.post<Cep>(`/validate/cep/${cep}`, JSON.stringify(data));
            return response.data;
        } catch (error: any) {
            return error.response.data;
        }
    };

    return { buscarCep };
}