import { ActionService } from "../../servicos/action.service";
import { removeMask, validarCPF } from "../../utils/utils";
import { LeadService } from "../../servicos";
import { CheckoutDTO } from "../../dto";
import { useEffect, useState } from "react";

export const Logic = (props: { alterarShowError: any, configuration: any, handleSubmit: any, form: any, formularioPagamento: any, callRecaptcha: any }) => {


    const [sendedLead, alterarSendedLead] = useState(false);

    const [modalShow, setModalShow] = useState(false);

    const [termos, alterarTermos] = useState(false);
    const [termosSmsEmail, alterarTermosSmsEmail] = useState(false);
    const [termosInvalido, alterarTermosInvalido] = useState(false);
    const [termosMensagemErro, alterarTermosMensagemErro] = useState("Campo obrigatório");
    function verificarTermos() {
        alterarTermosInvalido(termos === false);
        if (!termos)
            alterarTermosMensagemErro("Campo obrigatório");

        // ActionService().sendInvalidField(termos === false, termosMensagemErro, termos.toString(), "termos", configuration.ga_codigo!);
    }

    const [bankName, setBankName] = useState('');
    const [method, setMethod] = useState('');

    useEffect(() => {
        var form: CheckoutDTO = props.formularioPagamento();
        setMethod(form.forma_pagamento_selec!)

    }, [props.formularioPagamento])

    const [mostrarCredito, alterarMostrarCredito] = useState(false);
    const [mostrarDebito, alterarMostrarDebito] = useState(false);

    const [numeroCartao, alterarNumeroCartao] = useState("");
    const [numeroCartaoValido, alterarNumeroCartaoValido] = useState(false);
    const [numeroCartaoInvalido, alterarNumeroCartaoInvalido] = useState(false);
    const [numeroCartaoMensagemErro, alterarNumeroCartaoMensagemErro] = useState("Campo obrigatório");

    function verificarNumeroCartao() {
        if (props.alterarShowError)
            props.alterarShowError(false);

        var numeroCartaoTratado = removeMask(numeroCartao);
        var mesage = numeroCartaoMensagemErro;

        alterarNumeroCartao(numeroCartaoTratado);
        alterarNumeroCartaoInvalido(numeroCartaoTratado.length < 16);
        alterarNumeroCartaoValido(numeroCartaoTratado.length >= 16);

        if (numeroCartaoTratado.length <= 0)
            mesage = "Campo obrigatório";
        else
            mesage = "Número do cartão inválido";

        props.callRecaptcha();


        alterarNumeroCartaoMensagemErro(mesage);

        ActionService().sendInvalidField(numeroCartaoTratado.length < 16, mesage, numeroCartao, "numeroCartao", props.configuration.ga_codigo!);
    }

    const [nome, alterarNome] = useState("");
    const [nomeValido, alterarNomeValido] = useState(false);
    const [nomeInvalido, alterarNomeInvalido] = useState(false);
    const [nomeMensagemErro, alterarNomeMensagemErro] = useState("Campo obrigatório");
    function verificarNome() {
        if (props.alterarShowError)
            props.alterarShowError(false);

        var hasNumber = /\d/;
        var message = nomeMensagemErro;
        var invalid = nome.length < 3 || hasNumber.test(nome);

        alterarNomeInvalido(invalid);
        alterarNomeValido(nome.length >= 3 && !hasNumber.test(nome));

        if (nome.length <= 0)
            message = "Campo obrigatório";
        else if (hasNumber.test(nome)) {
            message = "Nome inválido";
            alterarNomeInvalido(true);
            alterarNomeValido(false);
        }
        else
            message = "Digite seu nome completo";


        alterarNomeMensagemErro(message);

        ActionService().sendInvalidField(invalid, message, nome, "nomeCartao", props.configuration.ga_codigo!);
    }

    const [cpf, alterarCpf] = useState("");
    const [cpfValido, alterarCpfValido] = useState(false);
    const [cpfInvalido, alterarCpfInvalido] = useState(false);
    const [cpfMensagemErro, alterarCpfMensagemErro] = useState("Campo obrigatório");
    function verificarCpf() {
        if (props.alterarShowError)
            props.alterarShowError(false);

        var cpfTratado = removeMask(cpf);
        var eValido: boolean = validarCPF(cpfTratado);
        var message = cpfMensagemErro;

        alterarCpf(cpfTratado);
        alterarCpfInvalido(!eValido);
        alterarCpfValido(eValido);
        props.callRecaptcha();

        if (cpfTratado.length <= 0)
            alterarCpfMensagemErro("Campo obrigatório");
        else {
            message = 'CPF inválido';
            alterarCpfMensagemErro(message);
        }

        ActionService().sendInvalidField(!eValido, message, cpfTratado, "cpfCartao", props.configuration.ga_codigo!);
    }

    const [validade, alterarValidade] = useState("");
    const [validadeValido, alterarValidadeValido] = useState(false);
    const [validadeInvalido, alterarValidadeInvalido] = useState(false);
    const [validadeMensagemErro, alterarValidadeMensagemErro] = useState("Campo obrigatório");
    function verificarValidade() {
        if (props.alterarShowError)
            props.alterarShowError(false);
        var validadeTratado = removeMask(validade);

        
        var mesAtual = new Date().getMonth() + 1;
        var anoAtual = Number.parseInt(new Date().getFullYear().toString().substring(2, 4));
        var anoInputed = Number.parseInt(validadeTratado.substring(2, 4));
        var mesInputed = Number.parseInt(validadeTratado.substring(0, 2));
        var invalid = false;
        var message = validadeMensagemErro;

        if ((anoInputed < anoAtual || mesInputed > 12) && validadeTratado.length === 4) {
            invalid = true;
            message = anoInputed < anoAtual ? "Ano inválido" : "Mês inválido";

            alterarValidadeInvalido(invalid);
            alterarValidadeMensagemErro(message);
        } else {
            alterarValidadeInvalido(false);

            if (anoInputed === anoAtual && mesInputed < mesAtual) {
                invalid = true;
                message = "Mês inválido";

                alterarValidadeInvalido(invalid);
                alterarValidadeMensagemErro(message);
            } else {
                invalid = validadeTratado.length < 3;

                alterarValidade(validadeTratado);
                alterarValidadeInvalido(invalid);
                alterarValidadeValido(validadeTratado.length >= 3);

                if (validadeTratado.length <= 0) {
                    message = "Campo obrigatório";
                }
                else {
                    message = "Cartão fora da Validade";
                }

                alterarValidadeMensagemErro(message);
            }
        }

        ActionService().sendInvalidField(invalid, message, validade, "validadeCartao", props.configuration.ga_codigo!);
    }

    const [cvv, alterarCvv] = useState("");
    const [cvvValido, alterarCvvValido] = useState(false);
    const [cvvInvalido, alterarCvvInvalido] = useState(false);
    const [cvvMensagemErro, alterarCvvMensagemErro] = useState("Campo obrigatório");
    function verificarCvv() {
        if (props.alterarShowError)
            props.alterarShowError(false);

        var cvvTratado = removeMask(cvv);

        var invalid = cvvTratado.length < 3;
        var message = cvvMensagemErro;

        alterarCvv(cvvTratado);
        alterarCvvInvalido(invalid);
        alterarCvvValido(cvvTratado.length >= 3);

        if (cvvTratado.length <= 0)
            message = "Campo obrigatório";
        else
            message = "CVV inválido";

        if (bankName === 'itau') {
            alterarCvv('');
            alterarCvvInvalido(false);
            alterarCvvValido(true);
        }

        alterarCvvMensagemErro(message);

        ActionService().sendInvalidField(invalid, message, cvv, "cvv", props.configuration.ga_codigo!);
    }

    function handleSubmit(event: any) {
        if (props.alterarShowError)
            props.alterarShowError(false);

        event?.preventDefault();
        event?.stopPropagation();

        verificarNumeroCartao();
        verificarNome();
        verificarValidade();
        verificarCvv();
        verificarCpf();

        if (numeroCartaoValido && nomeValido && validadeValido && cvvValido) {
            var generationtime = new Date().toISOString();

            const options = {
                cardTypeElement: 0,
                onsubmit: function (e: any) {
                    e.preventDefault();
                }
            };

            const encryptedForm = window.adyen.encrypt.createEncryptedForm(props.form.current, process.env.REACT_APP_API_KEY_ADYEN, options);
            const cardData = {
                number: numeroCartao,
                cvc: cvv,
                holderName: nome,
                expiryMonth: validade.substring(0, 2),
                expiryYear: validade.substring(2, 4),
                generationtime
            };
            const cryptData = encryptedForm.encrypt(cardData);

            var formCartao: any = cardData;

            formCartao.bankName = bankName;
            formCartao.method = method;
            formCartao.cryptData = cryptData;

            props.handleSubmit(event, formCartao);
        }
        else {
            props.handleSubmit(event);
        }
    }

    const openCredit = () => {
        alterarMostrarCredito(!mostrarCredito);
        alterarMostrarDebito(false);

        // if (!sendedLead && props.formularioPagamento !== undefined) {
        //     alterarSendedLead(true);
        //     var form: CheckoutDTO = props.formularioPagamento();
        //     form.etapalead = "Pagamento";
        //     LeadService().sendLead(form);
        // }
    }

    function enviarEtapaLead() {
        if (!sendedLead && props.formularioPagamento !== undefined) {
            alterarSendedLead(true);
            var form: CheckoutDTO = props.formularioPagamento();
            form.etapalead = "Pagamento";
            LeadService().sendLead(form);
        }
    }

    return {
        bankName, setBankName,
        method, setMethod,


        termos, alterarTermos, verificarTermos, termosInvalido, termosMensagemErro, termosSmsEmail, alterarTermosSmsEmail,
        modalShow, setModalShow,

        alterarMostrarCredito, mostrarCredito, mostrarDebito, numeroCartao, alterarNumeroCartao, numeroCartaoValido, numeroCartaoInvalido,
        numeroCartaoMensagemErro, nome, alterarNome, nomeValido, nomeInvalido, nomeMensagemErro, validade, alterarValidade, validadeValido,
        validadeInvalido, validadeMensagemErro, cvv, alterarCvv, cvvValido, cvvInvalido, cvvMensagemErro, cpf, alterarCpf, cpfValido, cpfInvalido,
        cpfMensagemErro, verificarNumeroCartao, verificarNome, verificarCpf, verificarValidade, verificarCvv, handleSubmit, openCredit, enviarEtapaLead
    };
}